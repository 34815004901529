import React, { useEffect, useRef, useState } from 'react'
import Container from "../../components/HOC/Container/Container"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Input from '../../components/Input/Input';
import FormTextarea from "../../components/FormTextarea/FormTextarea"
import TagInput from '../../components/TagInput/TagInput';
import "./Event.css"
import ImageInput from '../../components/ImageInput/ImageInput';
import auctionServices from '../../../services/auction-services';
import checkImageBeforeUpload from "../../../utils/checkImageBeforeUpload"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { saveEvent } from '../../../redux/savedEventReducer';
import { updateProperties } from '../../../redux/dependenciesReducer';
import DropDown from "../../components/DropDown/DropDown"
import { setDefaultEndDate, setDefaultStartDate } from '../../../utils/util-functions';
import Alert from '../../components/Alert/Alert';
import causeServices from '../../../services/cause-services';
import { FormControlLabel, InputAdornment, Radio, TextField, Tooltip, Typography } from '@mui/material';
import authService from '../../../services/auth-services';
import AccessDenied, { LiveEvent } from '../../components/AccessDenied/AccessDenied';
import Modal from '../../components/Modal/Modal';
import { RadioGroup, FormControl, FormLabel } from '@mui/material';

import moment from "moment"
import DatePickerComponent from '../../components/DatePicker/DatePicker';
import DateAlert from '../../components/Alert/dateConfirmAlert';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import constants, { COLORS_OBJECT } from '../../../constants/en';
import CustomCategoryPriorityModalComponent, { CustomCategoryHeadingPriorityModalComponent } from './CustomCategoryPriorityModal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import usePrompt from '../../hooks/usePrompt';
import CustomImagesModal from '../../components/CustomImagesModal/CustomImagesModal';
import RadioGroupComponent from '../../components/RadioGroup/RadioGroup';
import { clearAttribute, setAttribute } from '../../../redux/persistReducer';
import usePopState from '../../hooks/usePopState';
import ClearButton from '../../components/ClearButtonContainer/ClearButtonContainer';
import { setItemToLocalStorage } from '../../../utils/autoSave';
import { handleImageChange } from '../../../utils/image-utils';
import VisibilityModal from '../../components/VisibilityModal/VisibilityModal';

const redColor = COLORS_OBJECT.color[0]

const toLocalDate = (iso) => {
  const date = new Date(iso)
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let dh = date.getHours();
  let dm = date.getMinutes();
  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (dh < 10) {
    dh = "0" + dh
  }
  if (dm < 10) {
    dm = "0" + dm
  }
  return year + '-' + month + '-' + dt + " " + dh + ":" + dm;
}

const Event = () => {
  const savedEvent = useSelector(state => state.savedEvent)
  const [showPrompt, setShowPrompt] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const persistedData = useSelector(state => state?.persistedData)
  const { auction_id } = useParams()
  const [alertMsg, setAlertMsg] = useState(null)
  const localStorageData = JSON.parse(localStorage.getItem("eventData"))
  const localStorageExtraEventData = JSON.parse(localStorage.getItem("extraEventState")) || {}
  const [customCategoryImages, setCustomCategoryImages] = useState({});
  const [customCategoryImagePreview, setCustomCategoryImagePreview] = useState({});
  const [portraitImageLoading, setPortraitImageLoading] = useState(false)
  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  const [browseAuctionImageLoading, setBrowseAuctionImageLoading] = useState(false)
  const [browseBuyNowImageLoading, setBrowseBuyNowImageLoading] = useState(false)
  const [customCategoryImageLoading, setCustomCategoryImageLoading] = useState({})
  const [videoLoading, setVideoLoading] = useState(false)
  const [videos, setVideos] = useState([]);
  const [userCauses, setUserCauses] = useState([])
  const [selectedCause, setSelectedCause] = useState(localStorageExtraEventData?.eventSelectedCause ? localStorageExtraEventData?.eventSelectedCause : null)
  // const [fixedTags, setFixedTags] = useState([])
  const [showCustom, setShowCustom] = useState(false)
  const [showCustomDiscount, setShowCustomDiscount] = useState(false);
  const [editAccess, setEditAccess] = useState(true)
  const [openModal,setOpenModal]=useState(false);
  const [openModal2,setOpenModal2]=useState(false);
  const [saveCustomCategory,setSaveCustomCategory] = useState(false);
  const [customCategoryHeadline, setCustomCategoryHeadline] = useState({ id: '', heading: '', category: [], tempId:null, fixedCategory: [] });
  const [customCategories,setCustomCategories]=useState(localStorageExtraEventData?.eventCustomCategories ? localStorageExtraEventData?.eventCustomCategories : []);
  const [clearTags,setClearTags]=useState(false);
  const [selectedTags,setSelectedTags]=useState([])
  // const [isLive, setIsLive] = useState(false);
  const [dateChange,setDateChange] = useState(false);
  const [startDate,setStartDate] = useState("");
  const [endDate,setEndDate] = useState("");
  const [browseAuctionImage, setBrowseAuctionImage] = useState([])
  const [browseBuyNowImage, setBrowseBuyNowImage] = useState([])
  const [deleteCustomCatId,setDeleteCustomCatId] = useState(null)
  const [customCategoryAlert,setCustomCategoryAlert] = useState(null)
  const [allCustomCategoryAlert,setAllCustomCategoryAlert] = useState(null)
  const [priorityOrderModal, setPriorityOrderModal] = useState(false)
  const [headingPriorityOrderModal, setHeadingPriorityOrderModal] = useState(false)
  const [priorityCategory, setPriorityCategory] = useState({})
  const [customCategoryHeadingId, setcustomCategoryHeadingId] = useState({})
  const [customCategoryByHeadingAlert, setCustomCategoryByHeadingAlert] = useState(null)
  const [customCategoryByHeading, setCustomCategoryByHeading] = useState({})
  const [navigateAction, setNavigateAction] = useState('')
  const [noCustomCategoryImageAlert, setnoCustomCategoryImageAlert] = useState(false)

  const [customImageModal, setCustomImageModal] = useState(false)
  const [customImages, setCustomImages] = useState({})
  // const [newImagePermission, setNewImagePermission] = useState({normalUser:false, godUser: false})
  const [newImagePermission, setNewImagePermission] = useState(false)
  const [godPermission, setGodPermission] = useState(false)

  /// state for checking if user has permission for uploading images for customcategories
  const [customCategoryImagePermission, setCustomCategoryImagePermission] = useState(false)

  let confirmdateChange = useRef();


  let initialState = {
    id: null,
    title: '' ,
    cause_id: '',
    cause_category_id: '',
    start_date: '',
    end_date: '',
    preview_periods_days: 0,
    goal: '',
    currency: 'USD',
    shipping_info: '',
    terms_info: '',
    about: '' ,
    custom_categories: [],
    video: '',
    video1: '',
    image1: '' ,
    image2: '',
    custom_url: '',
    auction_image_key:'',
    buy_now_image_key:'',
    status:'',
    event_logo:'',
    has_auction: 1,
    has_buy_now: 1,
    auction_label:"Items up for Auction",
    auction_btn_name:"Explore the Auction",
    buy_now_label:"Our Shop",
    buy_now_btn_name:"Shop Now",
    need_box: '0',
    box_color: '' ,
    text_color: '' ,
    featured_image:'',
    remain_visible: false,
    show_details_over_banner:true,
    show_details_over_store_page_banner:true,
    ui: false,
    show_details_over_auction_page_banner:true
  }

  const initialStateCopy = {...initialState}
  
  if(localStorageData) {
    if(auction_id) {
     localStorage.removeItem('eventData')
     localStorage.removeItem('extraEventState')
    }
    initialState = localStorageData
    if(persistedData?.eventLogoImage) {
      initialState = {...initialState, event_logo:persistedData?.eventLogoImage}
    }
    if(persistedData?.eventPortraitImage) {
      initialState = {...initialState, image1:persistedData?.eventPortraitImage}
    }
    if(persistedData?.eventLandscapeImage) {
      initialState = {...initialState, image2:persistedData?.eventLandscapeImage}
    }
  }

  const [eventData, setEventData] = useState(initialState)  

  const [portraitImage, setPortraitImage] = useState(
     (persistedData?.eventPortraitImage instanceof Blob || (localStorageData?.image1 !== '' && typeof localStorageData?.image1 != 'object')) ? localStorageExtraEventData?.eventPortrait : null);
  const [landscapeImages, setLandscapeImages] = useState(
    (persistedData?.eventLandscapeImage instanceof Blob || (localStorageData?.image2 !== '' && typeof localStorageData?.image2 != 'object') ) && localStorageExtraEventData?.eventLandscape ? localStorageExtraEventData?.eventLandscape : []
  );
  
  const [hasAuction, setHasAuction] = useState(Object.keys(localStorageExtraEventData).includes('eventHasAuction') ? localStorageExtraEventData?.eventHasAuction : true);
  const [hasBuyNow, setHasBuyNow] = useState(Object.keys(localStorageExtraEventData).includes('eventHasBuyNow') ? localStorageExtraEventData?.eventHasBuyNow : true);
  // const [eventLogo,setEventLogo] = useState((persistedData?.eventLogoImage && localStorageExtraEventData?.eventLogoImage) ? localStorageExtraEventData?.eventLogoImage : null);
  const [eventLogo,setEventLogo] = useState((localStorageExtraEventData?.eventLogoImage) ? localStorageExtraEventData?.eventLogoImage : null);
  const [eventLogoLoading,setEventLogoLoading] = useState(false)
  const [touchedLabelRadio, setTouchedLabelRadio] = useState(false)

  usePrompt("Do you want to leave?", showPrompt, 'eventData', eventData)
  usePopState('eventData', eventData, showPrompt)
  
  const [boxColorPicker, setBoxColorPicker] = useState(initialState?.need_box == '1' ? true : false)
  const [eventAdded, setEventAdded] = useState(false)

  const [selectedPreviewOption, setSelectedPreviewOption] = useState(localStorageExtraEventData?.eventSelectedPreviewOption ? localStorageExtraEventData?.eventSelectedPreviewOption : { value: "0", label: "No Preview" })
  const previewOptions = [{ value: "0", label: "No Preview" }, { value: 1, label: "1 day" }, { value: 2, label: "2 days" }, { value: 3, label: "3 days" }, { value: 4, label: "4 days" }, { value: 5, label: "5 days" }, { value: 6, label: "6 days" }, { value: 7, label: "7 days" }, { value: 8, label: "8 days" }, { value: 9, label: "9 days" }, { value: 10, label: "10 days" }, { value: 11, label: "11 days" }, { value: 12, label: "12 days" }, { value: 13, label: "13 days" }, { value: 14, label: "14 days" }, { value: 15, label: "15 days" }, { value: 16, label: "16 days" }, { value: 17, label: "17 days" }, { value: 18, label: "18 days" }, { value: 19, label: "19 days" }, { value: 20, label: "20 days" }, { value: 21, label: "21 days" }, { value: 22, label: "22 days" }, { value: 23, label: "23 days" }, { value: 24, label: "24 days" }, { value: 25, label: "25 days" }, { value: 26, label: "26 days" }, { value: 27, label: "27 days" }, { value: 28, label: "28 days" }, { value: 29, label: "29 days" }, { value: 30, label: "30 days" }]
  
  const [featuredIconLoading, setFeaturedIconLoading] = useState(false);
  const [featuredImage, setFeaturedImage] = useState([]);

  const [clearCategoryImageState, setClearCategoryImageState] = useState([])
  const [allCustomCategories, setAllCustomCategories] = useState([])

  const [updateVisibilityModal, setUpdateVisibilityModal] = useState({open:false})
  const [uiType, setUiType] = useState(false)

  
  //fetching custom categories
  const fetchCustomCategories = async()=>{
    try{
      const custom_categories = await auctionServices.fetchcustomCategoryHeadline(auction_id)
      let newCustomList = []
      let customCategotyImagePreviewVar={}
      setnoCustomCategoryImageAlert(false)
      custom_categories?.data?.map((c, i) => {
        let categories = c?.auction_custom_categories?.map((category) =>{
          let tempId = `${Math.random()}${new Date().getTime()}`
          customCategotyImagePreviewVar[tempId] = category?.image_key
         return {id:category?.id,name:category?.category_name,image_key:category?.image_key,tempId, show_everywhere:category?.show_everywhere}
        })
        let heading = c.name
        let id = c.id
        newCustomList.push({ id, heading, fixedCategory: categories, category: [] ,  })
        return null
      })

      setCustomCategoryImagePreview(customCategotyImagePreviewVar)
      setCustomCategories(newCustomList)
    }catch(error) {}

    try {
      // this will fetch all the custom categories not heading.
      let customCategotyImagePreviewVar={}
      const allCustomCategoriesByAuctionId = await auctionServices.fetchCustomCategories(auction_id)
      console.log(allCustomCategoriesByAuctionId, 'at response')
      const newArray = allCustomCategoriesByAuctionId?.data?.map((category) => {
        let tempId = `${Math.random()}${new Date().getTime()}`
        customCategotyImagePreviewVar[tempId] = category?.image_key
        return {id:category?.id,name:category?.category_name,image_key:category?.image_key,tempId}
      })
      setAllCustomCategories(newArray)
    } catch(error) {}
  }

  const checkEventEditAccess = async() => {
    try {
      const permission = await authService.checkPermissions(4, auction_id)
      setIsLoading(false)
      if (!permission.access) {
        setEditAccess(false)
        return
      } else {
        setEditAccess(true)
      }
    }catch(error) {
      setEditAccess(false)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    checkEventEditAccess()
  },[])

  const fetchAuctionDetails = async (auction_id) => {
    try {
      setIsLoading(true)
      const response = await auctionServices.getAuctionDetailsById(auction_id);
      
      setUiType(response?.data?.ui === 'flash_sale' ? true : false);

      // if (response?.data?.status === 'live') {
      //   setIsLive(true)
      //   setIsLoading(false);
      //   return
      // }
      // const custom_categories = await auctionServices.fetchCustomCategories(auction_id)
      // const newCustomList = custom_categories?.data?.map(c => c.category_name) 

      fetchCustomCategories()
      if(response.data?.status === "complete"){
        // only god user can edit completed event
        const godPermission = await authService.checkPermissions(15, auction_id)
        if (!godPermission.access) {
          setEditAccess(false)
          setIsLoading(false)
          return
        } else {
          setEditAccess(true)
        }
      }

        setEventData({
          ...response?.data,
          goal: (response?.data?.goal) ? response?.data?.goal : "NA",
          start_date: toLocalDate(response?.data?.start_date),
          end_date: toLocalDate(response?.data?.end_date),
          custom_categories: []
        });
        setEventAdded(true)

      if(response?.data.has_auction == '1')setHasAuction(true);
      else setHasAuction(false);
      if(response?.data.has_buy_now == '1') setHasBuyNow(true);
      else setHasBuyNow(false);

      if (response?.data.preview_periods_days == 0) {
        setSelectedPreviewOption({ value: response?.data.preview_periods_days, label: "No Preview" })
      } else if (response?.data.preview_periods_days === 1) {
        setSelectedPreviewOption({ value: response?.data.preview_periods_days, label: `${response?.data.preview_periods_days} Day` })
      } else {
        setSelectedPreviewOption({ value: response?.data.preview_periods_days, label: `${response?.data.preview_periods_days} Days` })
      }
      setStartDate(response?.data?.start_date);
      setEndDate(response?.data?.end_date);
      // setFixedTags(newCustomList)
      if (response?.data?.image2) {
        setPortraitImage([{ name: response?.data?.image1+'_small', type: 'img' }])
        setLandscapeImages([{ name: response?.data?.image2+'_small', type: 'img' }])
      } else {
        setPortraitImage([{ name: response?.data?.image1+'_small', type: 'img' }])
      }
      if(response.data?.auction_image_key){
        setBrowseAuctionImage([{name:response.data?.auction_image_key, type:'img'}])
      }
      if(response.data?.buy_now_image_key){
        setBrowseBuyNowImage([{name:response.data?.buy_now_image_key, type:'img'}])
      }
      if (response?.data?.video) setVideos([{name:response?.data?.video}]);
      if(response.data?.event_logo){
        setEventLogo([{name:response.data?.event_logo, type:'img'}])
      }
      if(response?.data?.need_box === '1') {
        setBoxColorPicker(true)
      }
      else setBoxColorPicker(false)
      if(response?.data?.featured_image) {
        setFeaturedImage([{name:response.data?.featured_image, type:'img'}])
      }
      if(response?.data?.ui === "flash_sale"){
        setShowCustomDiscount(true)
      }
      setIsLoading(false);
    } catch (error) {
      navigate("/admin/my-events")
    }
  }

  useEffect(() => {
    if (customCategories.length) {
      setShowCustom(true)
    }
  }, [customCategories])


  useEffect(() => {
    userCauses.forEach((cause) => {
      if (cause.value.id === eventData.cause_id && cause.value.category_id === eventData.cause_category_id) {
        setSelectedCause(cause)
      }
    })
  }, [eventData.cause_id, eventData.cause_category_id, userCauses])

  useEffect(() => {
    (async () => {
      const data = await causeServices.getUserCauses()
      const newCauses = data?.data?.map(c => ({ value: { id: c.cause.id, category_id: c.cause.cause_category_id, label: c.cause.legal_name }, label: c.cause.legal_name }))
      setUserCauses(newCauses)
    })()
  }, [])

  const handleCauseChange = (e) => {
    setShowPrompt(true)
    setEventData({
      ...eventData,
      cause_id: e.value.id,
      cause_category_id: e.value.category_id
    })
    setSelectedCause(e)
  }

  // useEffect(() => {
  //   if (!auction_id) return
  //   fetchAuctionDetails(auction_id)
  // }, [])

  useEffect(() => {
    if (!auction_id) return
    if(!eventData?.id) {
      fetchAuctionDetails(auction_id)/// calling the api once more. It is because the setEventData state is not updating all time initially.
    }
  },[eventAdded])

  useEffect(() => {
    if(auction_id) return;
    let extraEventState = {}
    if(selectedCause) {
      extraEventState = {...extraEventState, eventSelectedCause:selectedCause}
    }
    // if(portraitImage !== null && portraitImage !== undefined) {
    //   extraEventState = {...extraEventState, eventPortrait:portraitImage}
    // }
    if(portraitImage !== null && portraitImage !== undefined && !portraitImage[0]?.name?.startsWith('data:image')) {
      extraEventState = {...extraEventState, eventPortrait:portraitImage}
    }
    if(landscapeImages?.length && !landscapeImages[0]?.name?.startsWith('data:image')) {
      extraEventState = {...extraEventState, eventLandscape:landscapeImages}
    }
    // if(eventLogo !== null) {
    //   extraEventState = {...extraEventState, eventLogoImage:eventLogo}
    // }
    if(selectedPreviewOption?.value != '0') {
      extraEventState = {...extraEventState, eventSelectedPreviewOption: selectedPreviewOption}
    }
    if(customCategories?.length) {
      extraEventState = {...extraEventState, eventCustomCategories: customCategories}
    }
    if(touchedLabelRadio) {
      extraEventState = {...extraEventState, eventHasAuction: hasAuction}
      extraEventState = {...extraEventState, eventHasBuyNow: hasBuyNow}
    }
    if(Object.keys(extraEventState)?.length > 0) {
      // localStorage.setItem("extraEventState", JSON.stringify(extraEventState))
      setItemToLocalStorage('extraEventState', extraEventState)
    }
  },[portraitImage, landscapeImages, hasAuction, hasBuyNow, selectedPreviewOption, customCategories, selectedCause])

  useEffect(() => {
    if (savedEvent.event && !auction_id) {
      setEventData(savedEvent.event)
      setPortraitImage(savedEvent.portraitImage || null)
      setLandscapeImages(savedEvent.landscapeImage)
      setBrowseAuctionImage(savedEvent.browseAuctionImage)
      setBrowseBuyNowImage(savedEvent.browseBuyNowImage)
      setVideos(savedEvent.vidoes)
    }
  }, [])


  const [isLoading, setIsLoading] = useState(false);
  const [confirmWhiteBorder,setConfirmWhiteBorder] = useState(false);
  const [whiteBorderAlert,setWhiteBorderAlert] = useState(null);
  const [file,setFile] =useState(null);
  const [location,setLocation] = useState(null);

  // const handleMultipleVideos = (e) => {
  //   if (e.target.files.length === 0) return
  //   setShowPrompt(true)
  //   setVideoLoading(true)
  //   const filesUrl = []
  //   const targetFilesObj = [...e.target.files]
  //   if (targetFilesObj.length > 2 || landscapeImages.length === 2 || (landscapeImages.length + targetFilesObj.length) > 2) {
  //     setVideoLoading(false)
  //     alert(constants.ADMIN_COPY_EVENT_VIDEO_LIMIT );
  //     return
  //   }
  //   if (targetFilesObj.every((currentValue) => currentValue.size <= 20000000)) {
  //     targetFilesObj.map((file) => {
  //       return filesUrl.push({ name: URL.createObjectURL(file), type: 'video', local: true });
  //     });
  //     setVideos([...videos, ...filesUrl])
  //     if (targetFilesObj.length > 2) {
  //       setEventData({
  //         ...eventData,
  //         video: targetFilesObj[0],
  //         video1: targetFilesObj[1]
  //       })
  //     } else {
  //       if (!eventData.video) {
  //         setEventData({
  //           ...eventData,
  //           video: targetFilesObj[0],
  //         })
  //       } else {
  //         setEventData({
  //           ...eventData,
  //           video1: targetFilesObj[0],
  //         })
  //       }
  //     }
  //     setVideoLoading(false)
  //   } else {
  //     setVideoLoading(false)
  //     alert(constants.ADMIN_COPY_EVENT_VIDEO_FILE_SIZE);
  //     return
  //   }
  // };

  const handleDataChange = (e) => {
    // if(e.target.name === 'start_date' || e.target.name === 'end_date') {
    //   if(!e.target.valueAsDate) {
    //     return
    //   }
    // }

    if(e.target.name === 'custom_url'){
      if(e.target.value){
        e.target.value = e.target.value.replace(/ /g,'-')
      }
    }
    
    if(e.target.name.includes('/title')){
      e.target.name='title';
    }

    setShowPrompt(true)
    setEventData({
      ...eventData,
      [e.target.name]: e.target.value
    })

    
  }
  const handleStartDateChange = (value) => {
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setEventData({
      ...eventData,
      start_date: date
    })
  }

  const handleEndDateChange = (value) => {
    const date = moment(value).format("YYYY-MM-DDTHH:mm")
    setEventData({
      ...eventData,
      end_date: date
    })
  }
  const handleHeadlineEdit = (tempId, id, e) => {

    const edited = customCategories.map((c) => {
      if (c.tempId === tempId && c.tempId !== '' && c.tempId) {

        return { ...c, heading: e.target.value }
      } else if (c.id === id && c.id !== '' && c.id) {

        return { ...c, heading: e.target.value }
      }
      else {
        return c
      }

    })
    setCustomCategories(edited)

  }
  const handleCategoryEdit = (tempId, id, newCategories) => {
    const edited = customCategories.map((c) => {
      if (c.tempId === tempId && c.tempId !== '' && c.tempId ) {

        return { ...c, category: newCategories }
      } else if (c.id === id && c.id !== '' && c.id) {

        return { ...c, category: newCategories }
      }
      else {
        return c
      }

    })
    setCustomCategories(edited)

  }

  const handleHeadlineChange = (e) => {
    if(e.target?.value?.length > 95) return setAlertMsg(constants.ADMIN_COPY_MAX_CHAR_CUSTOM_HEADING_CATEGORY)
    setCustomCategoryHeadline({ ...customCategoryHeadline, heading: e.target.value })
    setClearTags(false)

  }

  const handleCategoryChange = (newCategories) => {
    setCustomCategoryHeadline({ ...customCategoryHeadline, category: newCategories, tempId: `${Math.random()}${new Date().getTime()}` })
    setSelectedTags(newCategories)
    setShowPrompt(true)
  }

  const addNewCustomCategory = () => {
    if (customCategoryHeadline.heading.trim()  !== '' && customCategoryHeadline.category.length) {
      setCustomCategories([customCategoryHeadline, ...customCategories])
      setSelectedTags([])
      setClearTags(true)
      setCustomCategoryHeadline({ id: '', heading: '', category: [], tempId: '' })
    }

  }

    // const handleNewCategoryChange = (newCategories) => {
    //   // According to current requirement in ticket 1399 we are making using this function istead of handleCategoryChange
    //   setShowPrompt(true)
    //   // setDefaultStateChange({...defaultStateChange, customCategoryChange:true})
    //   setCustomCategories([{...customCategories[0],id:'',heading:"", category: newCategories, tempId: `${Math.random()}${new Date().getTime()}` }])
    // }

    // const handleCustomImageModal = () => {
    //   if(godPermission) {
    //     setOpenModal2(true)
    //   }
    // }

  // useEffect(() => {
  //   setEventData(prevValue=>({
  //     ...prevValue,
  //     custom_categories: customCategories
  //   }))
  // }, [customCategories])

  //validating all custom category have curresponding images
  // const validateCustomCategoryImages = ()=>{
  //   let flag = true
  //   customCategories.map(heading=>{
  //     heading.category.map(category=>{
  //       if(!customCategoryImages[category.tempId]){
  //         flag = false
  //       }
  //       return null
  //     })
  //     return null
  //   })
  //   return flag


  // }

  const saveCustomCategoryChange = async() => {
    setIsLoading(true)
     try{
       // if(validateCustomCategoryImages()){ /// for checking images are equal to the length of added custom categories not needed now
       if (customCategories && Object.keys(customCategories).length ) {
        if(auction_id){

          const formData = new FormData()
          formData.append("customCategories",JSON.stringify(customCategories))
  
         for(let tempId in customCategoryImages){
          formData.append("customCategoryImages",customCategoryImages[tempId],`${tempId}`)
         }
  
         if(clearCategoryImageState?.length) {
          formData.append("clear_image_category_ids", JSON.stringify(clearCategoryImageState))
         }
        await auctionServices.addCustomCategories(auction_id,formData).then((res)=>{
          // console.log(res)
           // setCustomCategories([])
        setCustomCategoryImages({})
        setClearCategoryImageState([])
        // setCustomCategoryImagePreview({})
        fetchCustomCategories()
        }).catch((error)=>{
          // console.log(error);
          setAlertMsg(error?.response?.data?.message)
          return
        })
       
        }else{
          setEventData({
            ...eventData,
            custom_categories: customCategories,
            custom_category_images:customCategoryImages
          })
        }
      }
      // }else{
        //   setIsLoading(false)
        //   alert(constants.ADMIN_COPY_EVENT_CUSTOM_CATEGORY_IMG)
        // }
        // fetchCustomCategories()
     }catch (error) {
     console.log(error)
    }finally{
      setIsLoading(false)
      setOpenModal2(false)
     }
    
  }
  
  const openCustomCategoryImageUpload = async() => {
    addNewCustomCategory()
    // if(customCategoryImagePermission || godPermission) {
    if(godPermission) {
      setOpenModal(false)
      setOpenModal2(true)
    }
    else {
      setSaveCustomCategory(!saveCustomCategory)
      setOpenModal(false)
    }
  }

  // useEffect(()=>{
  //   // this method (in useEffect with saveCustomCategory dipendancy) of calling saveCustomCategoryChange() 
  //   // help us to get a delay to save custom categoryHeadline to customCategory array.
  //   saveCustomCategoryChange();
  // },[saveCustomCategory])

  const deleteCustomCategoryImage = (tempId, headingId, categoryId) => {
    setCustomCategoryImagePreview({...customCategoryImagePreview,[tempId]:null})
    // to delete image from backend
    if(headingId && categoryId) {
      setClearCategoryImageState([...clearCategoryImageState, categoryId])
    }
  }

  const handleCustomCategoryImage = async (e,tempId) => {
     try{
      if (!e.target.files[0]) return
      setShowPrompt(true)
      setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:true})
      const image = e.target.files[0]
      if (image.size > 5000000) {
        // setCustomCategoryImageLoading(false)
        setShowPrompt(false)
        setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
        alert(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE);
        return
      }
      const imageUrl = URL.createObjectURL(image)
      const { width, height } = await checkImageBeforeUpload(imageUrl)
      if (width < 375 || height < 450) {
        // setCustomCategoryImageLoading(false)
        setShowPrompt(false)
        setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
        alert(constants.ADMIN_COPY_CUSTOM_CATEGORY_IMAGE_DIMENSIONS)
        return
      }
      const formData = new FormData()
      formData.append("image", image)
      formData.append("width", 375)
      formData.append("height", 450)
      const preProcessedImage = await auctionServices.imagePreProcess(formData)
      setCustomCategoryImagePreview({ ...customCategoryImagePreview,[tempId]: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`})
      const uint8 = new Uint8Array(preProcessedImage.buffer.data)
      const newBlob = new Blob([uint8], { type: "image/jpeg" })
      setCustomCategoryImages({...customCategoryImages,[tempId]:newBlob})
      // setCustomCategoryImageLoading(false)
      setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
      setShowPrompt(false)
     }
     catch(error){
      // setCustomCategoryImageLoading(false)
      setCustomCategoryImageLoading({...customCategoryImageLoading,[tempId]:false})
      setShowPrompt(false)
     }
   
  }
 
  const handlePortraitImage = async (e,loc) => {
    try{
    if (!e.target.files[0]) return
    setShowPrompt(true)
    setPortraitImageLoading(true)
    const image = e.target.files[0]
    // if (image.size > 5000000) {
    //   setShowPrompt(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
    //   setPortraitImageLoading(false)
    //   return
    // }
    // const imageUrl = URL.createObjectURL(image)
    // const { width, height } = await checkImageBeforeUpload(imageUrl)
    // if (width < 375 || height < 450) {
    //   setShowPrompt(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_SIZE)
    //   setPortraitImageLoading(false)
    //   return
    // }
    // if(((width/height < 0.8167) || (width/height > 0.833)) && !confirmWhiteBorder){
    //     setFile(file);
    //     setLocation(loc)
    //     setWhiteBorderAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG)
    //     return
    // }
    const formData = new FormData();
    formData.append("image", image)
    formData.append("width", 1125)
    formData.append("height", 1350)
    const preProcessedImage = await auctionServices.imagePreProcess(formData)
    setPortraitImage([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
    const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    const newBlob = new Blob([uint8], { type: "image/jpeg" })
    setEventData({
      ...eventData,
      'image1': newBlob
    })
    setPortraitImageLoading(false)
    // dispatch(setAttribute({key: 'eventPortraitImage', value: newBlob}))
    // setShowPrompt(true)
    return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
    }
    catch(error){
    setPortraitImageLoading(false)
    // setShowPrompt(false)
    }
  }

  const handleLandscapeImage = async (e) => {
   try{
    if (!e.target.files[0]) return
    setShowPrompt(true)
    setLandscapeImageLoading(true)
    const image = e.target.files[0]
    // if (image.size > 5000000) {
    //   setShowPrompt(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
    //   setLandscapeImageLoading(false)
    //   return
    // }
    // const imageUrl = URL.createObjectURL(image)
    // const { width, height } = await checkImageBeforeUpload(imageUrl)
    // if (width < 636 || height < 318) {
    //   setShowPrompt(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_LANDSCAPE_IMG_SIZE)
    //   setLandscapeImageLoading(false)
    //   return
    // }
    const formData = new FormData()
    formData.append("image", image)
    formData.append("width", 1908)
    formData.append("height", 954)
    const preProcessedImage = await auctionServices.imagePreProcess(formData)
    setLandscapeImages([{ name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img' }])
    const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    const newBlob = new Blob([uint8], { type: "image/jpeg" })
    setEventData({
      ...eventData,
      'image2': newBlob
    })
    setLandscapeImageLoading(false)
    // setShowPrompt(false)
    // dispatch(setAttribute({key: 'eventLandscapeImage', value: newBlob}))
    return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
   }
   catch(error){
    setLandscapeImageLoading(false)
    // setShowPrompt(false)
   }
  }
  const handleEventLogo = async (e) => {
    try{
      if (!e.target.files[0]) return
    setShowPrompt(true)
    setEventLogoLoading(true)
    const image = e.target.files[0]
    if (image.size > 5000000) {
      setShowPrompt(false)
      setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
      setEventLogoLoading(false)
      return
    }
    const imageUrl = URL.createObjectURL(image)
    const { width, height } = await checkImageBeforeUpload(imageUrl)
    if (width < 80 || height < 80) {
      setShowPrompt(false)
      setAlertMsg("Image is too small it should be at least 80x80PX")
      setEventLogoLoading(false)
      return
    }
    // const formData = new FormData()
    // formData.append("image", image)
    // formData.append("width", 375)
    // formData.append("height", 450)
    // console.log(image)
    // const preProcessedImage = await auctionServices.imagePreProcess(formData)
    setEventLogo([{ name: imageUrl, type: 'img' }])
    // const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    // const newBlob = new Blob([uint8], { type: "image/jpeg" })
    setEventData({
      ...eventData,
      'event_logo': image
    })
    // dispatch(setAttribute({key: 'eventLogoImage', value:image}))
    setEventLogoLoading(false)
    // setShowPrompt(false)
    }
    catch(error){
    setEventLogoLoading(false)
    // setShowPrompt(false)
    }
  }

  const handleDeleteEventLogo = ()=>{
    setEventLogo([])
    setEventData({
      ...eventData,
      event_logo: null
    })
    dispatch(clearAttribute({key:'eventLogoImage'}))
  }

  const handleBrowseAuctionItemImage = async (e) => {
    try{
      if(!e.target.files[0]) return
    setShowPrompt(true)
    setBrowseAuctionImageLoading(true)
    const auctionBrowseImage = e.target.files[0]
    // if(auctionBrowseImage.size > 5000000){
    //   setIsLoading(false)
    //   setShowPrompt(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
    //   setBrowseAuctionImageLoading(false)
    //   return
    // }
    const auctionBrowseImageUrl = URL.createObjectURL(auctionBrowseImage)
    const {width, height} = await checkImageBeforeUpload(auctionBrowseImageUrl)
    // if(width < 300 || height < 200) {
    //   setIsLoading(false)
    //   setShowPrompt(false)
    //   setBrowseAuctionImageLoading(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
    //   return
    // }
    const formData = new FormData()
    formData.append("image", auctionBrowseImage)
    formData.append("width", 300)
    formData.append("height", 200)
    const preProcessedImage = await auctionServices.imagePreProcess(formData)
    setBrowseAuctionImage([{name:  `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
    const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    const newBlob = new Blob([uint8], { type: "image/jpeg" })
    setEventData({
      ...eventData,
      'auction_image_key': newBlob
    })
    setBrowseAuctionImageLoading(false)
    // setShowPrompt(false)
    return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
    }
    catch(error){
      setBrowseAuctionImageLoading(false)
      setShowPrompt(false)
    }
  }

  const handleBrowseBuyNowItemImage = async (e) => {
    try{
      if(!e.target.files[0]) return
    setShowPrompt(true)
    setBrowseBuyNowImageLoading(true)
    const buyNowBrowseImage = e.target.files[0]
    // if(buyNowBrowseImage.size > 5000000){
    //   setIsLoading(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_IMG_FILE_SIZE)
    //   setBrowseBuyNowImageLoading(false)
    //   return
    // }
    const buyNowBrowseImageUrl = URL.createObjectURL(buyNowBrowseImage)
    const {width, height} = await checkImageBeforeUpload(buyNowBrowseImageUrl)
    // if(width < 300 || height < 200) {
    //   setIsLoading(false)
    //   setAlertMsg(constants.ADMIN_COPY_EVENT_BROWSE_IMG_SIZE)
    //   setBrowseBuyNowImageLoading(false)
    //   return
    // }
    const formData = new FormData()
    formData.append("image", buyNowBrowseImage)
    formData.append("width", 300)
    formData.append("height", 200)
    const preProcessedImage = await auctionServices.imagePreProcess(formData)
    setBrowseBuyNowImage([{name:  `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
    const uint8 = new Uint8Array(preProcessedImage.buffer.data)
    const newBlob = new Blob([uint8], { type: "image/jpeg" })
    setEventData({
      ...eventData,
      'buy_now_image_key': newBlob
    })
    setBrowseBuyNowImageLoading(false)
    // setShowPrompt(false)
    return `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`
    }
    catch(error){
    setBrowseBuyNowImageLoading(false)
    setShowPrompt(false)
    }
  }
  
const handleFormSubmit =async (e) =>{
  e.preventDefault();
  setShowPrompt(true);
  confirmdateChange.current = "false"
  if(auction_id){
      if(((setDefaultStartDate(eventData.start_date)) !== startDate) || ((setDefaultStartDate(eventData.end_date)) !== endDate) ){
        setDateChange(!dateChange);
      }else{
        await handleSubmit();
      }
  }else{
   await handleSubmit();
  }
}
  async function handleSubmit () {
    // if(!eventData.auction_image_key || !eventData.buy_now_image_key){
    //   setIsLoading(false)
    //   setAlertMsg("You Need To Provide Images For Browsing Auction Items")
    //   return;
    // }
    setShowPrompt(false)
    await saveCustomCategoryChange()
      // if(eventData.has_auction == '0' && eventData.has_buy_now == '0') {
      //   setAlertMsg(constants.ADMIN_COPY_EVENT_HASAUCTION_AND_HASBUYNOW_VALIDATION);
      //   return
      // }
    
    if(eventData.title){
        eventData.title = eventData.title?.replace(/\s+/g, " ")?.trim();
    }
    if(eventData.title?.length < 2 || eventData.title?.length > 60) {
      setIsLoading(false)
      setAlertMsg("Event "+constants.ADMIN_COPY_TITLE_VALIDATION)
      return;
    }
    if(!eventData.end_date || !eventData.start_date) {
      setIsLoading(false)
      setAlertMsg(constants.ADMIN_COPY_EVENT_DATES_VALIDATION)
      return;
    }
    if(!eventData.cause_id || eventData.cause_id === '') {
      setIsLoading(false)
      setAlertMsg(constants.ADMIN_COPY_CAUSE_ID_REQUIRED)
      return;
    }
    if(!auction_id) {
      
      // if(!eventData.image1 || !eventData.image2) {
      //   setIsLoading(false)
      //   setAlertMsg(constants.ADMIN_COPY_EVENT_NO_IMG)
      //   return
      // }
      if(!eventData.image1 || (eventData?.image1 instanceof Blob === false && typeof eventData?.image1 === 'object') || !eventData.image2 || (eventData?.image2 instanceof Blob === false && typeof eventData?.image2 === 'object')) {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_EVENT_NO_IMG)
        return;
     }
     
      if (isNaN(eventData.goal) && eventData.goal.toLocaleLowerCase() !== "NA".toLocaleLowerCase()) {
        setAlertMsg(constants.ADMIN_COPY_EVENT_GOAL);
        return 
      }

      if(hasAuction){
        if(eventData?.auction_label === ""){
          setAlertMsg("Auction label cann't be empty");
          return
        }
        if(eventData?.auction_btn_name === ""){
          setAlertMsg("Auction button name cann't be empty");
          return
        }
       }
       if(hasBuyNow){
        if(eventData?.buy_now_label === ""){
          setAlertMsg("Buy Now label cann't be empty");
          return
        }
        if(eventData?.buy_now_btn_name === ""){
          setAlertMsg("Buy Now button name cann't be empty");
          return
        }
        if(eventData?.ui){
          if(eventData?.discount_percentage === null){
            setAlertMsg("Flash Sale discount percentage cannot be empty");
          }
        }
       }

      if (eventData.custom_url) {
        const regex = /^[a-zA-Z0-9-]+$/;
        // if (eventData.custom_url.trim() === '') {
        //   setIsLoading(false)
        // }
         if (eventData.custom_url.length < 3 || eventData.custom_url.length > 50) {
          setIsLoading(false)
          setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_LENGTH)
        }
        else if (!eventData.custom_url.match(regex)){
           setIsLoading(false)
           setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_REGEX_ERR)
        }
        else {
          // setShowPrompt(false)
       const response = await auctionServices.checkCustomUrl(eventData.custom_url)
       if(response.message === 'Custom URL already exists try a different one'){
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_EXIST)
       }
       else {
        setShowPrompt(false)
          dispatch(saveEvent({
            event: eventData,
            portraitImage: portraitImage,
            landscapeImage: landscapeImages,
            browseAuctionImage:browseAuctionImage,
            browseBuyNowImage:browseBuyNowImage,
            event_logo:eventLogo,
            videos: videos,
            featuredImage:featuredImage
          }))
          // localStorage.setItem('eventData', JSON.stringify(eventData))/// saving the data in localstorage for persisting on come back.
          setItemToLocalStorage('eventData', eventData)
          navigate("/admin/terms")
        }
        }
      }
      else {
        setShowPrompt(false)
        dispatch(saveEvent({
          event: eventData,
          portraitImage: portraitImage,
          landscapeImage: landscapeImages,
          browseAuctionImage:browseAuctionImage,
          browseBuyNowImage:browseBuyNowImage,
          event_logo:eventLogo,
          videos: videos,
          featuredImage:featuredImage
        }))
        // localStorage.setItem('eventData', JSON.stringify(eventData))/// saving the data in localstorage for persisting on come back.
        setItemToLocalStorage('eventData', eventData)
        navigate("/admin/terms")
      }
    } else {
      if(!eventData.image1 || !eventData.image2) {
        setIsLoading(false)
        setAlertMsg(constants.ADMIN_COPY_EVENT_NO_IMG)
        return
      }
      
      if (isNaN(eventData.goal) && eventData.goal.toLocaleLowerCase() !== "NA".toLocaleLowerCase()) {
        setAlertMsg(constants.ADMIN_COPY_EVENT_GOAL);
        return
      }
      if(hasAuction){
        if(eventData?.auction_label === ""){
          setAlertMsg("Auction label cann't be empty");
          return
        }
        if(eventData?.auction_btn_name === ""){
          setAlertMsg("Auction button name cann't be empty");
          return
        }
       }
       if(hasBuyNow){
        if(eventData?.buy_now_label === ""){
          setAlertMsg("Buy Now label cann't be empty");
          return
        }
        if(eventData?.buy_now_btn_name === ""){
          setAlertMsg("Buy Now button name cann't be empty");
          return
        }
        if(eventData?.ui){
          if(eventData?.discount_percentage === null){
            setAlertMsg("Flash Sale discount percentage cannot be empty");
          }
        }
       }
      try {
        setShowPrompt(false)
        setIsLoading(true)
        let auctionDetailsFormData = new FormData();
        eventData.title &&
          auctionDetailsFormData.append('title', eventData.title.replace(/\s+/g, " ").trim());
        eventData.cause_id &&
          auctionDetailsFormData.append('cause_id', eventData.cause_id);
        eventData.cause_category_id &&
          auctionDetailsFormData.append(
            'cause_category_id',
            eventData.cause_category_id
          );
        eventData.start_date &&
          auctionDetailsFormData.append(
            'start_date',
            setDefaultStartDate(eventData.start_date)
          );
        eventData.end_date &&
          auctionDetailsFormData.append(
            'end_date',
            setDefaultEndDate(eventData.end_date)
          );
        eventData.preview_periods_days &&
          auctionDetailsFormData.append(
            'preview_periods_days',
            eventData.preview_periods_days
          );
        eventData.currency &&
          auctionDetailsFormData.append('currency', eventData.currency);
        auctionDetailsFormData.append('shipping_info', eventData.shipping_info);
        auctionDetailsFormData.append('terms_info', eventData.terms_info);
        eventData.about &&
          auctionDetailsFormData.append('about', eventData.about);
        eventData.image1 &&
          auctionDetailsFormData.append('imageData0', eventData.image1);
        eventData.image2 &&
          auctionDetailsFormData.append('imageData1', eventData.image2);
        eventData?.featured_image && 
          auctionDetailsFormData.append('featured_image', eventData?.featured_image)
        eventData.video &&
          auctionDetailsFormData.append('video', eventData.video);
        auctionDetailsFormData.append('confirmDateChange', confirmdateChange.current );

        eventData.auction_image_key && 
          auctionDetailsFormData.append('auction_image_key_updating', eventData.auction_image_key);

          eventData.buy_now_image_key && 
          auctionDetailsFormData.append('buy_now_image_key_updating', eventData.buy_now_image_key);

          eventData.event_logo && auctionDetailsFormData.append("event_logo_updating",eventData.event_logo)
          auctionDetailsFormData.append('has_buy_now', eventData?.has_buy_now);
          auctionDetailsFormData.append('has_auction', eventData?.has_auction);
          auctionDetailsFormData.append('need_box', boxColorPicker ? '1' : '0');
          eventData.box_color && auctionDetailsFormData.append("box_color", eventData.box_color );
          eventData.text_color && auctionDetailsFormData.append("text_color", eventData.text_color );
          auctionDetailsFormData.append("remain_visible", eventData.remain_visible ? 1 : 0)
          auctionDetailsFormData.append("show_details_over_banner", eventData.show_details_over_banner ? 1 : 0)
          auctionDetailsFormData.append("show_details_over_store_page_banner", eventData.show_details_over_store_page_banner ? 1 : 0)
          auctionDetailsFormData.append("ui", eventData.ui ? 'flash_sale' : 'default')
          if(eventData.ui) {
            auctionDetailsFormData.append("discount_percentage", eventData.discount_percentage);
          }
          auctionDetailsFormData.append("show_details_over_auction_page_banner", eventData.show_details_over_auction_page_banner ? 1 : 0)
        if (eventData.goal)
          eventData.goal && (!isNaN(eventData.goal)) &&
            auctionDetailsFormData.append('goal', eventData.goal);
        eventData.goal && (isNaN(eventData.goal)) && eventData.goal.toLocaleLowerCase() === "NA".toLocaleLowerCase() &&
          auctionDetailsFormData.append('goal', "NA");
        if(hasAuction){
          auctionDetailsFormData.append('auction_label',eventData?.auction_label);
          auctionDetailsFormData.append('auction_btn_name',eventData?.auction_btn_name)
        }
        if(hasBuyNow){
          auctionDetailsFormData.append('buy_now_label',eventData?.buy_now_label);
          auctionDetailsFormData.append('buy_now_btn_name',eventData?.buy_now_btn_name)
        }
        if (eventData.custom_url) {
          const regex = /^[a-zA-Z0-9-]+$/;
          // if (eventData.custom_url.trim() === '') {
          //   setIsLoading(false)
          // }
           if (eventData.custom_url.length < 3 || eventData.custom_url.length > 50) {
            setIsLoading(false)
            setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_LENGTH)
          }
          else if (!eventData.custom_url.match(regex)){
            setIsLoading(false)
            setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_REGEX_ERR)
         }
          else {
            auctionDetailsFormData.append('custom_url', eventData.custom_url.replace(/ /g, "-"))
            await auctionServices.updateAuction(auction_id, auctionDetailsFormData)
            // if(eventData.custom_categories && Object.keys(eventData.custom_categories).length) {
            //   await auctionServices.addCustomCategories(auction_id, eventData.custom_categories)
            // }
            eventData.start_date && dispatch(updateProperties({ key: "start_date", value: eventData.start_date }))
            eventData.end_date && dispatch(updateProperties({ key: "end_date", value: eventData.end_date }))
            dispatch(updateProperties({ key: "has_auction", value: eventData.has_auction }))
            dispatch(updateProperties({ key: "has_buy_now", value: eventData.has_buy_now }))
            setIsLoading(false)

            if(navigateAction === "terms"){
              navigate(`/admin/terms/edit/${auction_id}`, {state:{capitalName:"Event", lowerName:"event"}})
            }
            else {
               setAlertMsg(constants.ADMIN_COPY_EVENT_UPDATE)
            }
          }
        }
        else {
          await auctionServices.updateAuction(auction_id, auctionDetailsFormData)
          // if(eventData.custom_categories && Object.keys(eventData.custom_categories).length) {
          //   await auctionServices.addCustomCategories(auction_id, eventData.custom_categories)
          // }
         
          eventData.start_date &&  dispatch(updateProperties({key: "start_date", value:eventData.start_date}))
          eventData.end_date && dispatch(updateProperties({key: "end_date", value: eventData.end_date}))
          dispatch(updateProperties({ key: "has_auction", value: eventData.has_auction }))
          dispatch(updateProperties({ key: "has_buy_now", value: eventData.has_buy_now }))
        
          setIsLoading(false)
          if(navigateAction === "terms"){
            navigate(`/admin/terms/edit/${auction_id}`, {state:{capitalName:"Event", lowerName:"event"}})
          }
          else {
            setAlertMsg(constants.ADMIN_COPY_EVENT_UPDATE)
          }

        }
        localStorage.setItem("data", JSON.stringify({
          auction_id: eventData.id,
          auction_title: eventData.title,
          start_date: eventData.start_date && setDefaultStartDate(eventData.start_date),
          end_date: eventData.end_date && setDefaultEndDate(eventData.end_date),
          has_auction: eventData.has_auction,
          has_buy_now: eventData.has_buy_now
      }))
      } catch (error) {
        setIsLoading(false)       
        if(error?.response?.data?.message === 'This Custom URL already exists. Please try a different one'){
          setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_EXIST)
        }        
      }
    }
  }

  // const handleCreateCause = () => {
  //   setShowPrompt(false)
  //   dispatch(saveEvent({
  //     event: eventData,
  //     portraitImage: portraitImage,
  //     landscapeImage: landscapeImages,
  //     videos: videos
  //   }))
  //   navigate("/admin/cause/add", { state: { from: "event" } })
  // }

  const deletePortrait = () => {
    setPortraitImage(null)
    setEventData({
      ...eventData,
      image1: ''
    })
  }

  const deleteLandscape = () => {
    setLandscapeImages([])
    setEventData({
      ...eventData,
      image2: ''
    })
  }

  const delteAuctionBrowseImage = () => {
    setBrowseAuctionImage([])
    setEventData({
      ...eventData,
      auction_image_key: null
    })
  }

  // const delteBuyNowBrowseImage = () => {
  //   setBrowseBuyNowImage([])
  //   setEventData({
  //     ...eventData,
  //     buy_now_image_key:null
  //   })
  // }

  // const deleteVideo = (url, imageIdx) => {
  //   setVideos(videos.filter(v => v.name !== url))
  //   if (imageIdx === 0) {
  //     setEventData({
  //       ...eventData,
  //       video: null
  //     })
  //   } else {
  //     setEventData({
  //       ...eventData,
  //       video1: null
  //     })
  //   }
  // }

  const handlePreviewDaysChange = (e) => {
    setShowPrompt(true)
    setEventData({
      ...eventData,
      preview_periods_days: e.value
    })
    setSelectedPreviewOption({ value: e.value, label: e.label })
  }

  const handleCloseChangeDate =() =>{ 
  confirmdateChange.current = "false"
    setEventData({
      ...eventData,
      start_date: toLocalDate(startDate),
      end_date: toLocalDate(endDate)
    })
    setDateChange(!dateChange)
    handleSubmit();
  }

  const handleChangeDateConfirm =() =>{
    confirmdateChange.current = "true"
    setDateChange(!dateChange)
    setStartDate(setDefaultStartDate(eventData.start_date));
    setEndDate(setDefaultEndDate(eventData.end_date));
      handleSubmit();
  }

  const deleteCustomCategoryAlert = (id) => {
    setDeleteCustomCatId(id)
    setCustomCategoryAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG)
  }

  const handleDeleteCustomCategory = async() => {
    setCustomCategoryAlert(null)
    setIsLoading(true)
    try{
      const response = await auctionServices.deleteCustomCategoryById(deleteCustomCatId,auction_id)
      if(response){
        setAlertMsg(response?.message)
        fetchCustomCategories()
      }
      setIsLoading(false)
    }catch(error){
      setIsLoading(false)
      setAlertMsg(error.response.data.message)
    } 
  }

  const handleDeleteAllCustomCatsModal = () => {
    if(!auction_id){
      setAllCustomCategoryAlert(constants.ADMIN_COPY_ALL_CUSTOM_CATEGORY_CLEAR_STATE_MSG)
    }
    else {
      setAllCustomCategoryAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG)
    }
  }

  const handleDeleteAllCustomCats = async() => {
    setAllCustomCategoryAlert(null)
    if(!auction_id){
      setCustomCategories([])
      setShowCustom(false)
    }
    else {
        try{
          setIsLoading(true)
          const response = await auctionServices.deleteCustomCategoryByAuctionId(auction_id)
          if(response){
            setAlertMsg(response?.message)
            setCustomCategories([])
            setShowCustom(false)
          }
          setIsLoading(false)
        }catch(error){
          setIsLoading(false)
          setAlertMsg(error.response.data.message)
        }
    }
  }

  const updatePriorityOrderModal = (customCatId, heading) => {
    const newCust = customCategories?.filter((item) => item?.id === customCatId);
    setcustomCategoryHeadingId({customCatId: customCatId, heading: heading })
    setPriorityCategory(newCust[0]?.fixedCategory)
    setHeadingPriorityOrderModal(false)
    setPriorityOrderModal(true)
  }

  const updatePriorityOrderModalNew = () => {
      setPriorityCategory(allCustomCategories)
      setPriorityOrderModal(true)
  }

  const savePriorityOrder = () => {
    fetchCustomCategories()
  }

  const goToDisplayOrder = () => {
    setHeadingPriorityOrderModal(true)
  }

  const handleDeleteCustomCategoryByHeading = (customHeading) => {
      setCustomCategoryByHeading(customHeading)
      if(!customHeading?.fixedCategory?.length){
        setCustomCategoryByHeadingAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING_STATE_CLEAR)
      }
      else {
        setCustomCategoryByHeadingAlert(constants.ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING)
      }
    }

  const deleteAllCustomCatsByHeading = async() => {
    setCustomCategoryByHeadingAlert(null)
        try{
          if(!customCategoryByHeading?.fixedCategory?.length){
            setCustomCategories(customCategories.filter((item) => item.heading !== customCategoryByHeading.heading))
          }
          else {
          setIsLoading(true)
          const response = await auctionServices.deleteCustomCategoryByHeading(auction_id, customCategoryByHeading)
          if(response){
            setAlertMsg(response?.message)
          }
          fetchCustomCategories()
          setIsLoading(false)
        }
        }catch(error){
          setIsLoading(false)
          setAlertMsg(error.response.data.message)
        }
  }

  const handleGoBack = () => {
    setUpdateVisibilityModal({open:false})
    setOpenModal2(false)
    setOpenModal(true)
  }


  useEffect(() => {
    let count = 0
         customCategories?.map((item) => {      
         count = count+item.category.length
        } )

         if(count > Object.keys(customCategoryImages).length) {
          setnoCustomCategoryImageAlert(true)
         }
         else {
          setnoCustomCategoryImageAlert(false)
         }
         count = 0;
// setnoCustomCategoryImageAlert(flag?true : false)  
  },[customCategories, customCategoryImages])

  const handleCustomImageSelect = async(type) => {
    let response
    try {
      setIsLoading(true)
        /// call for custom event images
        response = await auctionServices.getCustomImages(`event_${type}`)
     
       setIsLoading(false)
       setCustomImageModal(true)
       setCustomImages({flag:type, images:response?.data})
      } catch (error) {
        setIsLoading(false)
        setCustomImageModal(false)
      } 
    }

    /// check if the user has the permission to upload extra images
    const verifyUserPermission = async () => {
      setIsLoading(true)
      try {
        const imageAccess = await authService.checkLimitedPermissions(19);
        if (imageAccess?.access) setNewImagePermission(true);
        const godAccess = await authService.checkLimitedPermissions(15);
        if (godAccess?.access) setGodPermission( true);
        const customCategoryPermissionCheck = await authService.checkLimitedPermissions(20);
        if(customCategoryPermissionCheck?.access) setCustomCategoryImagePermission(true);
          setIsLoading(false)
      } catch (error) {
          setIsLoading(false)
      }
  }
    
  useEffect(() => {
    verifyUserPermission()
}, [])

    const handleSelectedImage = (fileUrl, activeExtraState=false) => {
      setShowPrompt(true)
      let flag;
      if(activeExtraState) {
         const activeImageKey = fileUrl?.imageKey
         flag = fileUrl?.flag;
         if(flag === "portrait") {
          setPortraitImage([{ name: activeImageKey, type: 'img' }])
         }
         if(flag === "landscape") {
          setLandscapeImages([{ name: activeImageKey, type: 'img' }])
         }
         if(flag === "auction_item") {
          setBrowseAuctionImage([{ name: activeImageKey, type: 'img' }])
         }
         if(flag === "buy_now_item") {
          setBrowseBuyNowImage([{ name: activeImageKey, type: 'img' }])
         }
      }
      else {
        const imageKey = fileUrl?.imageKey?.image;
        const dbImage = fileUrl?.imageKey?.db_key;
        flag = fileUrl?.flag;

      if(flag === 'portrait' ) {
        setPortraitImage([{ name: imageKey, type: 'img' }])
          setEventData({
            ...eventData,
            'image1': dbImage
          })
        
      } 
      if(flag === 'landscape' ) {
        setLandscapeImages([{ name: imageKey, type: 'img' }])
          setEventData({
            ...eventData,
            'image2': dbImage
          })
       
      }
      if(flag === 'auction_item') {
        setBrowseAuctionImage([{name: imageKey, type:'img'}])
        setEventData({
          ...eventData,
          'auction_image_key': dbImage
        })
      }
      if(flag === 'buy_now_item' ) {
        setBrowseBuyNowImage([{name: imageKey, type:'img'}])
        setEventData({
          ...eventData,
          'buy_now_image_key': dbImage
        })
      }
    }
      setCustomImageModal(false)
    }

    const handleImageState = async(file, location) => {
      let e = {
         target: {
          files: [file]
         }
      }

      let res;
      if(location === 'portrait') {
        res = await handlePortraitImage(e)
        return res
      }
      if(location === 'landscape') {
        res = await handleLandscapeImage(e)
        return res
      }
      if(location === 'auction_item') {
        res = await handleBrowseAuctionItemImage(e)
        return res
      }
      if(location === 'buy_now_item') {
        res = await handleBrowseBuyNowItemImage(e)
        return res
      }
    }

    useEffect(()=> {
      setEventData(prevValue=>({...prevValue, has_auction: !hasAuction  ? 0 : 1}))
    },[hasAuction])

    useEffect(()=> {
      setEventData(prevValue=>({...prevValue, has_buy_now: !hasBuyNow  ? 0 : 1}))
    },[hasBuyNow])

    const onBrowseAuctionRadioChange = (booleanValue) => {
         setHasAuction(booleanValue)
         setTouchedLabelRadio(true)
         if(!booleanValue) {
          setEventData({...eventData, auction_image_key: null})
          setBrowseAuctionImage([])
         }
    }

    const onBrowseBuyNowRadioChange = (booleanValue) => {
      setHasBuyNow(booleanValue)
      setTouchedLabelRadio(true)
      if(!booleanValue) {
        setEventData({...eventData, buy_now_image_key: null})
        setBrowseBuyNowImage([])
       }
    }

    const onHasAuctionHasBuyNowChange = (booleanValue) => {
      setHasAuction(booleanValue)
      setHasBuyNow(booleanValue)
      setTouchedLabelRadio(true)
      if(!booleanValue) {
        setEventData({...eventData, auction_image_key: null, buy_now_image_key:null})
        setBrowseAuctionImage([])
        setBrowseBuyNowImage([])
      }
    }

    useEffect(() => {
      if(boxColorPicker) {
        setEventData(prevValue=>({
          ...prevValue, need_box:'1'
        }))
      }
      else {
        setEventData(prevValue=>({
          ...prevValue, need_box:'0'
        }))
      }
    },[boxColorPicker])

    const handleNeedBoxColorPickerChange = (value) => {
      setShowPrompt(true)
      setBoxColorPicker(value)
    }

    const eventImageInputLabel=(label)=>(<p>{label}: Showcase your event with a background image from our library.<span className='asterisk_color' >*</span>{`( If you’d like to add your own custom photo, send an email notifying us at `}<a style={{color:'#060679'}} href="mailto:help@boundlessgiving.com">help@boundlessgiving.com</a>{`. Write “Custom Photo Request” in the subject line.)`}</p>)
    
    const clearUnsavedChanges = () => {
      localStorage.removeItem('eventData')
      localStorage.removeItem('extraEventState')
      if(auction_id) {
        fetchAuctionDetails(auction_id)
      }
      else {
        setEventData(initialStateCopy)
        setHasAuction(true)
        setHasBuyNow(true)
        setPortraitImage(null)
        setLandscapeImages([])
        setEventLogo(null)
        setShowCustom(false)
        setSelectedCause(null)
        setSelectedPreviewOption({ value: "0", label: "No Preview" })
        setCustomCategories([])
        setBoxColorPicker(false)
        dispatch(clearAttribute({key:'eventLogoImage'}))
        dispatch(clearAttribute({key:'eventPortraitImage'}))
        dispatch(clearAttribute({key:'eventLandscapeImage'}))
      }
    }

    const handleFeaturedIconChange = async(event) => {
      const {preProcessedImage, newBlob, imgError} = await handleImageChange(setFeaturedIconLoading, event, 942, 280, setAlertMsg, [], [] )
      if(imgError) return;
      setFeaturedImage([
        {
          name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`,
          type: "img",
        }
      ])
      setEventData({
        ...eventData,
        featured_image: newBlob
      })
    }

    const deleteFeaturedImage = () => {
      setFeaturedImage([])
      setEventData({
        ...eventData,
        featured_image: null
      })
    }

    const handleShowAfterCompleteChange = (e) => {
      setShowPrompt(true)
      setEventData({...eventData, remain_visible:e})
    }

    const onDetailsOverBannerChange = (e) => {
      setShowPrompt(true)
      setEventData({...eventData, show_details_over_banner:e})
    }
    const onDetailsOverStorePageBannerChange = (e) => {
      setShowPrompt(true)
      setEventData({...eventData, show_details_over_store_page_banner:e})
    }
    const onDetailsOverStoreAuctionTypeChange = (e) => {
      setShowPrompt(true)
      // console.log("--------------------------------", e);
      setUiType(e)
      setShowCustomDiscount(e);
      setEventData({...eventData, ui:e})
    }
    const onDetailsOverAuctionPageBannerChange = (e) => {
      setShowPrompt(true)
      setEventData({...eventData, show_details_over_auction_page_banner:e})
    }

    const handleUpdateVisibilityModal = () => {
      addNewCustomCategory()
      setUpdateVisibilityModal({open:!updateVisibilityModal.open})
    }

    const handleUpdateVisiblityModal = async() => {
      if(auction_id) {
        await saveCustomCategoryChange()
      }
      setUpdateVisibilityModal({open:false})
    }

    const [bulkDiscountError, setBulkDiscountError] = useState(false);

    const validateBulkDiscount = (value) => {
      if (value >= 0 && value <= 100) {
        setBulkDiscountError(false);
        handleDataChange({ target: { name: 'discount_percentage', value } }); // Only update the state if it's valid
      } else {
        setBulkDiscountError(true);
      }
    };

  return (
    <Container title={auction_id ? 'Edit Your Event' : "Step 2: Build Your Event"} style={{ position: "relative" }}>
      {auction_id && !editAccess ? <AccessDenied title="Edit Events" /> :
        // isLive ? <LiveEvent /> : (
          <>
           <div className='event_header' >
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <CalendarMonthOutlinedIcon sx={{ fontSize: "18px", color: "lightblue" }} />
              <div style={{ fontSize: "14px" }}>{auction_id ? "Edit An Event" : "Create a New Event"}</div>
           </div>
           {
            (!auction_id && (localStorageData || Object.keys(localStorageExtraEventData)?.length)) ?
            <ClearButton style={{padding:'0'}} onClick={clearUnsavedChanges}/>
            :null
           }
            </div>
            <hr style={{margin: "0"}} />
            <form id="myForm" onSubmit={handleFormSubmit} className='event-container'>
              <div>
                <Input maxLength={60} needCharacterCount={true} required label="Give Your Event A Title" value={eventData.title} onChange={handleDataChange} name={`${(Math.random() + 1).toString(36).substring(7)}/title`} autoComplete='no-fill' />
                {auction_id ? 
                 <DropDown value={{ label: eventData.causeDetails?.legal_name, value: eventData.causeDetails?.id }} isDisabled={true} label="Cause Name" required options={[]} /> 
                : <DropDown value={selectedCause} onChange={handleCauseChange} label="What Cause Is This Event Supporting?" required options={userCauses} />
                }
                <FormTextarea required label="Tell Us About Your Event" placeholder="Share as many details about your event that you'd like donors to know. Who it's for, why you are raising money. Is it annual? Whatever you want to share." value={eventData.about} onChange={handleDataChange} name="about" />
                <div className='event-two-inputs'>
                  {/* <Input type="datetime-local" min={moment().format("YYYY-MM-DDTHH:mm")} required label="Start Date" value={eventData.start_date} onChange={(e) => console.log(e.target.value)} name="start_date" />
                  <Input disabled={eventData.start_date === ""} type="datetime-local" min={eventData.start_date !== "" ? eventData.start_date : new Date().toISOString().split('T')[0]} required label="End Date" value={eventData.end_date} onChange={handleDataChange} name="end_date" /> */}


                  <DatePickerComponent disabled={ auction_id && (eventData.status === 'live' || eventData.status === 'complete' || eventData.status === 'paused')} minDateTime={moment().format("YYYY-MM-DDTHH:mm")} maxDateTime={eventData.end_date !== "" ? eventData.end_date : ''} date={eventData.start_date} onChange={handleStartDateChange} label={'Start Date'} />
                  <DatePickerComponent disabled={eventData.start_date === "" ||( auction_id && ( eventData.status === 'complete' || eventData.status === 'paused'))} minDateTime={eventData.start_date !== "" ? eventData.status === 'live' ? moment().format("YYYY-MM-DDTHH:mm") : eventData.start_date : moment().format("YYYY-MM-DDTHH:mm")} date={eventData.end_date} onChange={handleEndDateChange} label={'End Date'} />
                
                </div> 
                <DropDown isDisabled={ auction_id && (eventData.status === 'live' || eventData.status === 'complete' || eventData.status === 'paused')} menuPlacement='top' value={selectedPreviewOption} onChange={handlePreviewDaysChange} label="Preview Period (days)" required options={previewOptions} iButton infoBubbleText='Do you want to preview your event before it officially begins? If so, for how many days? If not, select “No Preview” ' />
                <Input type='text' label="Goal" value={eventData.goal} onChange={handleDataChange} name="goal" iButton infoBubbleText={constants.ADMIN_COPY_EVENT_GOAL_INPUT} />
                {/* <Input type='text' label="Custom Url"
                  value={eventData.custom_url} onChange={handleDataChange}
                  name="custom_url"
                  iButton
                  infoBubbleText='Add the custom url that you want to dispay 
                  in the url of your event. It must be unique, includes both letters and numbers.Example: "customurl123"'
                /> */}
             
          <div style={{margin: "10px 0", width:'100%', display:'flex', flexDirection:'column', textAlign:'left'}}>
                <label className='input-label' >Custom Url
                  <span style={{color: "red"}}>*</span>  {" "} 
                   <Tooltip title={"If you’d like to share a custom URL unique to your event, please enter it here. Your URL will begin with boundlessgiving.com/event/ and be followed by a combination of letters and numbers (required) of your choosing.You are only required to enter the unique combination of word(s) and number(s) to your event below. We’ll take care of the rest. For example: boundlessgiving.com/event/chaparralauction2023"}>
                    <InfoOutlinedIcon sx={{color:'#495057c4',fontSize:"16px", marginLeft: "4px"}} />
                    </Tooltip>
                    <div>
                     <span style={{fontSize: "0.7rem", color: "gray"}}>(Custom URL must be minimum 3 and maximum 50 characters.)
                     </span>
                    </div>
                </label>
                <TextField
                    className='MuiTextField-root'
                    variant='outlined'
                    value={eventData.custom_url} onChange={handleDataChange}
                    name="custom_url"
                    id="outlined-start-adornment"
                    InputProps={{
                      startAdornment: <InputAdornment position="start" sx={{marginRight:'0px !important'}} >
                          <p style={{fontSize:'0.85rem', fontFamily:'inherit'}} >https://boundlessgiving.com/event/</p> 
                         </InputAdornment>,
                       style: {
                             borderRadius: '20px',
                             outline:'none',
                             fontSize: '0.85rem',
                             height:'35px',
                             width:'100%',
                             maxWidth:'100%',
                             fontFamily:'inherit',
                             color:'black',
                       }
                    }}
                  />
          </div>

 

                <div>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label"><Typography sx={{fontSize:'0.85rem',fontWeight:'600',fontFamily:'AvenirNext, sans-serif',color:'black'}}>In addition to general item categories, would you like to create any custom categories?<Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>Select this if you’d like to customize your categories. Remember to create category headings and sub categories if applicable. For example, a you might choose “Golf equipment” as a heading and add “Irons” “Woods” and “Putters” as sub-categories.</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:'18px',marginLeft:'4px'}} /></Tooltip></Typography></FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel value="true" control={
                      <Radio checked={showCustom} sx={{"&.Mui-checked": {color: redColor,}}}/>} label="Yes" onChange={() => setShowCustom(true)} />
                       <FormControlLabel value="false" control={
                       <Radio checked={!showCustom} sx={{"&.Mui-checked": {color: redColor,}}}/>} label="No" onChange={() => setShowCustom(false)} />

                    </RadioGroup>
                  </FormControl>
                </div>
                          {/* {
                            showCustom && 
                            <>
                              <TagInput
                                // fixedTags={(customCategories[0]?.fixedCategory) ?? [] }
                                fixedTags={(allCustomCategories) ?? [] }
                                label="Add Custom Category"
                                name="custom_categories"
                                tags={(customCategories[0]?.category) ?? [] } 
                                handleChange={handleNewCategoryChange}
                                clearTags={clearTags}
                                deleteTags={deleteCustomCategoryAlert}
                              
                              />
                          <div className="marketStoreCategoryActions">
                             {
                               godPermission && 
                               <button type="button" onClick={handleCustomImageModal} className='aitem-modal-btn'>
                                  Upload Custom Category Images
                               </button>
                             }
                             <button type="button" onClick={() =>updatePriorityOrderModalNew()} className='aitem-modal-btn aitem-white-button'>
                               Update Display Order
                             </button>
                          </div>  
                          </>
                            } */}

                <div style={{ marginTop: "15px", display:'flex', justifyContent:'space-between' }}>
                {showCustom && 
                  <button type="button" onClick={() => setOpenModal(true)} className='aitem-modal-btn' style={{ fontSize: "0.8rem" }}>
                    {`Add${customCategories.length?'/Edit':''} Custom Category`}</button>
                }
                </div>

                {customCategories.length?<>
                <hr/>
                <div style={{display:'flex', justifyContent:"space-between"}}>
                  <button type="button" onClick={handleDeleteAllCustomCatsModal} className='aitem-modal-btn' style={{ fontSize: "0.8rem" }}>
                     Delete All Custom Category</button>
                  <button type="button" onClick={goToDisplayOrder} className='aitem-modal-btn' style={{ fontSize: "0.8rem" }}>
                     Update Display Order</button>  
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', overflowY: 'auto', maxHeight: '230px', marginTop:'30px', paddingInline:'10px', position:'relative' }}>
                  {/* to show an alert message for missing custom category images commented for now */}
                  {/* { noCustomCategoryImageAlert && <p style={{color:'#FE7099' ,fontSize:'16px'}}>There are some categories without images. Please <span style={{color:'#1c1cec94', cursor:'pointer'}} onClick={() => {setOpenModal2(true)}} >add images</span> to them; otherwise, they will not be saved</p>} */}
                    {
                      customCategories.map((c, i) => <div style={{display:'flex',gap:'10px',width:'100%'}} key={i}>  
                      <p style={{width:'45%'}}><strong>{c.heading}</strong>{'=> '}</p> 
                      <div style={{display:'flex',gap:'4px', flexWrap: "wrap" }}>
                      {c.category?.map((fc,i)=><p key={fc.name}> {`${fc.name}${c.category.length-2>=i?',':''}`}</p>)}                  
                      </div>
                      {c.fixedCategory?.length ? <div style={{display:'flex',gap:'4px', flexWrap: "wrap",width:'45%' }}>
                      {c.fixedCategory?.map((fc,i)=><p key={fc.name}> {`${fc.name}${c.fixedCategory.length-2>=i?',':''}`}</p>)}                  
                      </div> : null}
                      <div onClick={() => {handleDeleteCustomCategoryByHeading(c)}} style={{width:'10%',flex:1, cursor:'pointer'}} >
                        <Tooltip title={ <Typography sx={{fontSize:'0.85rem'}}>Delete this category and its associated custom categories.</Typography>}>
                           <div style={{ float:'right'}} className='table-btn'> <DeleteOutlineOutlinedIcon sx={{fontSize: "18px", color: "red",}} /> </div>
                         </Tooltip>
                      </div> 
                      </div>
                      )
                    }
                </div>
                </>:null}

                {
                  godPermission &&
                  <RadioGroupComponent 
                    label={constants.ADMIN_COPY_EVENT_SHOW_AFTER_COMPLETED_LABEL}
                    firstLabel="Yes"
                    secondLabel= "No"
                    onChange={handleShowAfterCompleteChange}
                    checked={eventData?.remain_visible}
                   />
                }

                <ImageInput
                  loading={featuredIconLoading}
                  // required
                  edit
                  onDelete={deleteFeaturedImage}
                  label={constants.ADIMIN_COPY_FEATURED_STORE_IMAGE_LABEL}
                  sidelabel={constants.ADMIN_COPY_FEATURED_IMG_SIZE}
                  handleChange={handleFeaturedIconChange}
                  images={featuredImage ? featuredImage : []}
                  max={1}
                />
                 <ImageInput
                  loading={eventLogoLoading}
                  edit
                  onDelete={handleDeleteEventLogo}
                  label="Showcase Your Event With Logo."
                  sidelabel="Upload an organization or event logo, Recommended size 80x80px"
                  handleChange={handleEventLogo}
                  images={eventLogo ? eventLogo : []}
                  max={1}
                  accept='.png'
                  // showCustomImageSelect={true}
                  // handleCustomImageSelect={() => handleCustomImageSelect('portrait')}
                />
               
                  
                <Modal isOpen={openModal} onClose={() => setOpenModal(false)} onDone={openCustomCategoryImageUpload} title="Custom category" buttonText={ 'Save And Continue' } extraButtonRequired={godPermission} extraButtonText={'Update Visibility'} onExtraButtonDone={handleUpdateVisibilityModal} >
                  <Input type='text' label="Custom category heading" value={customCategoryHeadline.heading} onChange={handleHeadlineChange} name="customCategory_headline" iButton 
                  infoBubbleText='You can create any category heading you’d like. All headings begin with “Browse by…” and you fill in the rest. Some examples include: “Teachers,”
                   “Grades,” “Featured Items,” “Sports,” “Artists,” “Locations” – anything you want.' />

                  <TagInput
                    // fixedTags={fixedTags}
                    label="Add Sub-Category"
                    name="custom_categories"
                    tags={customCategoryHeadline.category}
                    handleChange={handleCategoryChange}
                    clearTags={clearTags}
                  />
                  <div style={{ textAlign: "right" }}>
                    <button
                      type="button"
                      onClick={addNewCustomCategory}
                      style={{ marginTop: "10px" }}
                      className="aitem-modal-btn aitem-white-button"
                    >
                      + Add New category Heading
                    </button>
                  </div>
                  <hr />
                  {customCategories && customCategories.length > 0 && <h5>Edit Custom Categories</h5>}

                  <div style={{ display: 'flex', flexDirection: 'column', gap: '15px', overflowY: 'auto', maxHeight: '190px' }}>
                    {
                      customCategories.map((c, i) => <div key={i}>
                        <Input type='text' label="Custom category heading" value={c.heading} onChange={(e) => handleHeadlineEdit(c.tempId, c.id, e)} name="customCategory_headline" />
                        <TagInput
                          fixedTags={c.fixedCategory}
                          label="Add Sub-Category"
                          name="custom_categories"
                          tags={c.category}
                          handleChange={(newCategories) => handleCategoryEdit(c.tempId, c.id, newCategories)}
                          clearTags={false}
                          deleteTags={ deleteCustomCategoryAlert}
                        />

                        <hr />
                      </div>
                      )
                    }
                  </div>

                </Modal>

                <Modal buttonRequired={customCategories.length ? true : false} isOpen={openModal2} onDone={saveCustomCategoryChange} onClose={()=>setOpenModal2(false)} title='Upload image for your custom categories' buttonText='Save & Continue' extraButtonRequired extraButtonText={'Go Back'} onExtraButtonDone={handleGoBack} >
                 {customCategories.length ? 
                 
                 <>
                 <p>*Recommended size 375x450px </p>
                  {
                    customCategories.map((h, i) =><div key={h.heading} className='custom_category_container' >
                      <p><strong>{h.heading}</strong></p><hr/>
                      <div className='custom_category_image_container'>
                      {
                        h.category?.map((c)=>{
                         return <div key={c.tempId} style={{width:'100px'}}>
                          <ImageInput
                            loading={customCategoryImageLoading[c.tempId]}
                            edit
                            onDelete={()=>deleteCustomCategoryImage(c.tempId)}
                            handleChange={(e)=>handleCustomCategoryImage(e,c.tempId)}
                            images={customCategoryImagePreview[c.tempId]?[{name:customCategoryImagePreview[c.tempId]}] : []}
                            max={1}
                          />
                          <p style={{textAlign:'center'}} >{c.name}</p>
                        </div>
                        })
                      }
                      {
                        h.fixedCategory?.map((c)=>(
                        <div key={c.id} style={{width:'100px'}}>
                          <ImageInput
                            edit
                            loading={customCategoryImageLoading[c.tempId]}
                            onDelete={()=>deleteCustomCategoryImage(c.tempId, h?.id, c?.id)}
                            handleChange={(e)=>handleCustomCategoryImage(e,c.tempId)}
                            images={customCategoryImagePreview[c.tempId]?[{name:customCategoryImagePreview[c.tempId]}] :[] }
                            max={1}
                          />
                          <p style={{textAlign:'center'}} >{c.name}</p>
                        </div>
                        ))
                      }
                      </div>
                    </div>)
                  }
                  </>
                     : <h6 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px', color:'red'}} >Please create custom categories before uploading images.</h6>
                     
                     }
                </Modal>

            {/* Heading priority order modal */}

            <Modal isOpen={headingPriorityOrderModal}  onClose={()=>setHeadingPriorityOrderModal(false)} title='Update Heading Display Order' buttonRequired={false} >
            <>
          { customCategories?.length && auction_id ? 
            <CustomCategoryHeadingPriorityModalComponent
             category={customCategories}
             setPriorityCategory={setCustomCategories}
             onHeadingDone={savePriorityOrder}
             setIsLoading={setIsLoading}
             setAlertMsg={setAlertMsg}
             setHeadingPriorityOrderModal={setHeadingPriorityOrderModal}
             forCustomCategory={updatePriorityOrderModal}
           />
           : <h5 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px'}} >No Categories are saved</h5>
          }
            </>
            </Modal>

          {/* Priority order modal */}

          <Modal isOpen={priorityOrderModal}  onClose={()=>setPriorityOrderModal(false)} title='Update Custom Category Display Order' buttonRequired={false} >
          <>
          { priorityCategory?.length ?  
           <CustomCategoryPriorityModalComponent
             category={priorityCategory}
             setPriorityCategory={setPriorityCategory}
             onDone={savePriorityOrder}
             setIsLoading={setIsLoading}
             customCategoryHeadingId={customCategoryHeadingId}
             setAlertMsg={setAlertMsg}
             setPriorityOrderModal={setPriorityOrderModal}
           />
            : <h5 style={{fontWeight:600, textAlign:'center', paddingBlock:'20px'}} >No Categories are saved</h5>
          }
            </>
          </Modal>

          <Modal 
            isOpen={updateVisibilityModal.open}  
            onClose={()=>setUpdateVisibilityModal({open:false})} 
            title='Change Custom Category Visibility In App'
            onDone={handleUpdateVisiblityModal}
            buttonText='Save & Continue' 
            extraButtonRequired 
            extraButtonText={'Go Back'} 
            onExtraButtonDone={handleGoBack}
          >
            <VisibilityModal customCategories={customCategories} setCustomCategories={setCustomCategories}/>
          </Modal>

              </div>
              <div>
                <ImageInput
                  loading={portraitImageLoading}
                  // required
                  edit
                  onDelete={deletePortrait}
                  label={eventImageInputLabel("Event Portrait")}
                  // sidelabel={constants.ADMIN_COPY_CHARITY_PORTRAIT_IMAGE_SIDELABEL}
                  handleChange={handlePortraitImage}
                  images={portraitImage ? portraitImage : []}
                  max={1}
                  showCustomImageSelect={true}
                  handleCustomImageSelect={() => handleCustomImageSelect('portrait')}
                />
                <ImageInput
                  loading={landscapeImageLoading}
                  // required
                  edit
                  onDelete={deleteLandscape}
                  label={eventImageInputLabel('Event Landscape')}
                  // sidelabel={constants.ADMIN_COPY_CHARITY_LANDSCAPE_IMAGE_SIDELABEL}
                  handleChange={handleLandscapeImage}
                  images={landscapeImages ? landscapeImages : []}
                  max={1}
                  showCustomImageSelect={true}
                  handleCustomImageSelect={() => handleCustomImageSelect('landscape')}
                />
               <Input inputStyle={{width:'100%'}} type='color' name={'text_color'} label={constants.ADMIN_COPY_EVENT_TEXT_COLOR_LABEL} value={eventData.text_color} onChange={handleDataChange} iButton infoBubbleText='Select a color that will contrast well with your background images.'/>
              <RadioGroupComponent
                 checked={boxColorPicker}
                 onChange={handleNeedBoxColorPickerChange}
                 label={constants.ADMIN_COPY_EVENT_BOX_COLOR_RADIO} 
              />

                { boxColorPicker && <Input inputStyle={{width:'100%'}} type='color' name={'box_color'} label={constants.ADMIN_COPY_EVENT_BOX_COLOR_LABEL} value={eventData.box_color|| '#f0f0f0'} onChange={handleDataChange} />}

                 
                 <CustomImagesModal
                              permission={{newImagePermission:newImagePermission, godPermission:godPermission}}
                              handleImageState={handleImageState}
                              isOpen={customImageModal}
                              onClose={() => setCustomImageModal(false)}
                              onDone={handleSelectedImage}
                              images={customImages}
                              setIsLoading={setIsLoading}
                              stringLowerName="event"
                              setAlertMsg={setAlertMsg}
                              handleCustomImageSelect={handleCustomImageSelect}
                            />

                     {/* HasBuyNow and HasAuction start */}

                    <RadioGroupComponent 
                     checked={hasBuyNow}
                     onChange={onBrowseBuyNowRadioChange}
                     label={constants.ADMIN_COPY_EVENT_BUY_NOW_ITEMS_RADIO}
                    />
                                       {
                     (hasBuyNow) && 
                     <div className="hasAuctionBuyNowContainer">
                      <label className='input-label'>{constants.ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_LABEL[1]}</label>
                      <div className='hasAuctionBuyNowInputs'>
                        <Input style={{width:"45%"}} value={eventData.buy_now_label} onChange={handleDataChange} name='buy_now_label' autoComplete='no-fill'/>
                        <Input  style={{width:"45%"}} value={eventData.buy_now_btn_name} onChange={handleDataChange} name='buy_now_btn_name' autoComplete='no-fill'/>
                      </div>
                     </div>
                   }

                     <RadioGroupComponent 
                     checked={hasAuction}
                     onChange={onBrowseAuctionRadioChange}
                     label={constants.ADMIN_COPY_EVENT_AUCTION_ITEMS_RADIO}
                    />
                     {/* changed back to old one (separate buyNow and auction) according to ticket 1574 */}
                    {/* New Radio for both hasAuction and hasBuyNow */} 
                    {/* <RadioGroupComponent 
                     checked={hasAuction && hasBuyNow}
                     onChange={onHasAuctionHasBuyNowChange}
                     label={constants.ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_RADIO_LABEL}
                    /> */}
                    {
                      (hasAuction) && 
                      <div className="hasAuctionBuyNowContainer">
                      <label className='input-label'>{constants.ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_LABEL[0]}</label>
                      <div className='hasAuctionBuyNowInputs'>
                        <Input style={{width:"45%"}} value={eventData.auction_label} onChange={handleDataChange} name='auction_label' autoComplete='no-fill'/>
                        <Input  style={{width:"45%"}} value={eventData.auction_btn_name} onChange={handleDataChange} name='auction_btn_name' autoComplete='no-fill'/>
                      </div>
                      </div>
                    }


                     {/* HasBuyNow and HasAuction end */}

                     <RadioGroupComponent 
                     checked={eventData.show_details_over_banner}
                     onChange={onDetailsOverBannerChange}
                     label={constants.ADMIN_COPY_EVENT_DETAILS_OVER_BANNER_LABEL}
                    />
                     <RadioGroupComponent 
                     checked={eventData.show_details_over_auction_page_banner}
                     onChange={onDetailsOverAuctionPageBannerChange}
                     label={constants.ADMIN_COPY_EVENT_DETAILS_OVER_AUCTION_PAGE_BANNER_LABEL}
                    />
                     <RadioGroupComponent 
                     checked={eventData.show_details_over_store_page_banner}
                     onChange={onDetailsOverStorePageBannerChange}
                     label={constants.ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_BANNER_LABEL}
                    />
                    <RadioGroupComponent 
                     checked={uiType}
                     onChange={onDetailsOverStoreAuctionTypeChange}
                     label={constants.ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_AUCTION_TYPE}
                    />
       
                    {showCustomDiscount && (
                      <Input
                        type="number"
                        label={'Enter the bulk discount percentage'}
                        value={eventData.discount_percentage || 0}
                        onChange={(e) => validateBulkDiscount(Number(e.target.value))}
                        name='discount_percentage'
                        autoComplete='no-fill'
                        inputProps={{ min: 0, max: 100 }} // Restrict to between 0 and 100  
                        required
                        onInvalid={(e) => e.target.setCustomValidity("This field cannot be left blank")} // Custom validation message
                        onInput={(e) => e.target.setCustomValidity("")} // Clear the custom message on input
                      />
                    )}

                    {bulkDiscountError && (
                      <Typography sx={{ color: 'red', fontSize: '0.8rem', marginTop: '8px' }}>
                        Please enter a valid discount percentage (between 0 and 100).
                      </Typography>
                    )}

        {/* { (hasBuyNow || hasAuction ) &&
          <div style={{marginTop:'32px', position:'relative'}} >
              <label className='browse-image-input-label'>Showcase Your Event With An Image For 
                <Tooltip title={<Typography sx={{fontSize:'0.85rem'}}>
                Auctions and Store Items/tickets have their own storefronts. Select a custom image for each. If your event offers only one type of item (auction or store), then only upload one image and leave the other option blank.
                </Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize:'16px', marginLeft: "4px"}} /></Tooltip> 
                <div style={{fontSize: "0.7rem", color: "gray"}}>{constants.ADMIN_COPY_CHARITY_BROWSE_AUCTION_IMAGE_SIDELABEL}
                </div>
              </label>
              <div className='event_browse_image_wrapper'>
               { hasAuction && 
                <ImageInput
                  loading={browseAuctionImageLoading}
                  edit
                  onDelete={delteAuctionBrowseImage}
                  label="Browsing Auction Items."
                  // sidelabel="Max 1, Recommended size 300x200px"
                  handleChange={handleBrowseAuctionItemImage}
                  images={browseAuctionImage ? browseAuctionImage : []}
                  max={1}
                  showCustomImageSelect={true}
                  handleCustomImageSelect={() => handleCustomImageSelect('auction_item')}
                />
                }
              { hasBuyNow &&
                <ImageInput
                  loading={browseBuyNowImageLoading}
                  edit
                  onDelete={delteBuyNowBrowseImage}
                  label="Browsing Buy Now Items."
                  // sidelabel="Max 1, Recommended size 300x200px"
                  handleChange={handleBrowseBuyNowItemImage}
                  images={browseBuyNowImage ? browseBuyNowImage : []}
                  max={1}
                  showCustomImageSelect={true}
                  handleCustomImageSelect={() => handleCustomImageSelect('buy_now_item')}
                />
                }
              </div>
        </div>
        } */}
                {/* <ImageInput
                  loading={videoLoading}
                  label="Showcase Your Event With A Brief Video."
                  sidelabel="Up to 2, Max size 20MB each"
                  accept='video/*'
                  multiple={true}
                  handleChange={handleMultipleVideos}
                  images={videos ? videos : []}
                  max={2}
                  edit
                  onDelete={deleteVideo}
                /> */}
                <div className='event-needhelp'><a target='_blank' href="mailto:help@boundlessgiving.com">Need Help?</a></div>
                {/* <button className='event-submit-btn' type='submit'>{auction_id ? "Update Event" : "Save Event Details & Continue to Add Terms"}</button> */}
                { auction_id && (<div style={{width:'100%', display:'flex', justifyContent:'center'}}><button style={{width:'60%'}}  className='event-submit-btn aitem-white-button' type='submit'>Update Event</button></div>)}
                <button onClick={() => {setNavigateAction('terms'); setShowPrompt(false)}} className='event-submit-btn' type='submit'>{ `${auction_id ? "Update" : "Save"} Event & Continue to ${auction_id?'Update':'Add'}  Terms`}</button>
              </div>
            </form>
            <div style={{ fontSize: "0.9rem", marginTop: "20px", fontWeight: "600" }}><span style={{ color: "red" }}>*</span> - required fields</div>
            <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
          </>
        // )
        }
        <LoadingModal isOpen={isLoading} />
        <DateAlert message={constants.ADMIN_COPY_EVENT_DATE_EDIT_MSG} isOpen={dateChange} type="confirm" onClose={handleCloseChangeDate} onConfirm={handleChangeDateConfirm} />
        <Alert type='confirm' isOpen={!!customCategoryAlert} message={customCategoryAlert} onClose={() => setCustomCategoryAlert(null)} onConfirm={handleDeleteCustomCategory} />
        <Alert type='confirm' isOpen={!!allCustomCategoryAlert} message={allCustomCategoryAlert} onClose={() => setAllCustomCategoryAlert(null)} onConfirm={handleDeleteAllCustomCats} />
        <Alert type='confirm' isOpen={!!customCategoryByHeadingAlert} message={customCategoryByHeadingAlert} onClose={() => setCustomCategoryByHeadingAlert(null)} onConfirm={deleteAllCustomCatsByHeading} />

      </Container>
  )
}

export default Event