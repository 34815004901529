// add donor

const ADMIN_COPY_DONOR_TAX_ID_NUMERIC = 'Tax id must be a numeric'
const ADMIN_COPY_DONOR_SELECT_EVENT = 'Please select events associated to this donor'
const ADMIN_COPY_DONOR_FEATURED_IMAGE = "To have a donor featured, please provide both images."
const ADMIN_COPY_DONOR_FIRST_NAME = 'First name is required'
const ADMIN_COPY_DONOR_LAST_NAME = 'Last name is required'
const ADMIN_COPY_DONOR_ADDRESS = "Address is required"
const ADMIN_COPY_DONOR_STATE = "State is required"
const ADMIN_COPY_DONOR_CITY = "City is required"
const ADMIN_COPY_DONOR_ZIP = "Zip is required"
const ADMIN_COPY_DONOR_ABOUT = "Please write something about the organization"
const ADMIN_COPY_DONOR_TAXID = 'Please Enter taxid'
const ADMIN_COPY_DONOR_ORG_NAME = "Donor name is required and must need minimum chars of 2"
const ADMIN_COPY_DONOR_INVALID_FIRST_NAME = 'First name is invalid, must need minimum chars of 2'
const ADMIN_COPY_DONOR_INVALID_LAST_NAME = 'Last name is invalid, must need minimum chars of 2'
const ADMIN_COPY_DONOR_EMAIL = "Email is required"
const ADMIN_COPY_DONOR_EMAIL_INVALID = "Email is invalid"
const ADMIN_COPY_DONOR_SHARE_PERCENTAGE_LABEL = "Donor Percentage"
const ADMIN_COPY_BG_SHARE_PERCENTAGE_LABEL = "Boundless Percentage"
const ADMIN_COPY_CAUSE_SHARE_PERCENTAGE_LABEL = "Cause Percentage"
const ADMIN_COPY_DONOR_DROPSHIP_SALE_INTEGRATION_LABEL = "Order Updates and Reports"
const ADMIN_COPY_TOTAL_SHARE_VALIDATION = 'Total share must be 100%'
const ADMIN_COPY_INTEGRATION_TYPE_VALIDATION = 'Select a method to recieve Order Updates and Reports'
const ADMIN_COPY_CAUSE_FEATURED_LABEL = 'Do you want to make this cause as a featured cause?'
// donor promo

const ADMIN_COPY_DONOR_PROMO_FIELD_ERROR = 'Please fill the required field'

// bid management 

const ADMIN_COPY_BID_UPPER_BAND = 'Please add Upper Bound value'
const ADMIN_COPY_BID_INCREMENT = 'Please add an increment value'
const ADMIN_COPY_BID_UPPER_LOWER_ERROR = 'Upper Bound should be greater than Lower Bound'
const ADMIN_COPY_CUSTOM_BID_SAVE = 'Custom bid bands saved successfully'
const ADMIN_COPY_DEFAULT_BID_SAVE = 'Default bid saved successfully'

// cause 

const ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_LIMIT = 'Maximum number of landscape images are 3'
const ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_SIZE = 'Image is too small it should be at least 1908x954px.'
const ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE = 'File size should be less than or equal to 5MB'
const ADMIN_COPY_CAUSE_VIDEO_LIMIT = 'You can upload maximum 1 videos'
const ADMIN_COPY_CAUSE_COVER_IMG = 'Please upload at least 1 cause cover image!'
const ADIMIN_COPY_CAUSE_STATE = 'Please select a State!'
const ADMIN_COPY_CAUSE_CATEGORY = 'Please select a category!'
const ADMIN_COPY_CAUSE_FIRST_NAME = 'First name should not have spaces in between'
const ADMIN_COPY_CAUSE_LAST_NAME = 'Last name should not have spaces in between'
const ADMIN_COPY_CAUSE_ZIP_CODE = 'Please provide a valid zip code!'
const ADMIN_COPY_CAUSE_CREATE_FAILURE = 'Failed to create cause!'
const ADIMIN_COPY_CAUSE_COVER_PHOTO = 'Add up to three photos that visually represent your cause'
const ADMIN_COPY_CAUSE_LOGO_REQURIED = "A cause logo is required."
const ADMIN_COPY_CAUSE_LOGO_SIZE="Image is too small it should be at least 80x80PX"

// create blog

const ADMIN_COPY_BLOG_FILED_ERROR = 'All input fields must be filled'
const ADMIN_COPY_BLOG_TITLE_LENGTH = 'Title Must be at least 3 chars and max 100 chars long'
const ADMIN_COPY_BLOG_SUB_TITLE_LENGTH = 'Subtitle Must be at least 5 chars and max 300 chars long'
const ADMIN_COPY_BLOG_CONTENT_LENGTH= 'The content field requires a minimum of 200.'
const ADMIN_COPY_BLOG_IMAGE_SIZE = 'Please ensure that your image is sized to 1000x500 pixels'
const ADMIN_COPY_BLOG_IMG_FILE_SIZE = 'Files size cannot exeed 10MB'
const ADMIN_COPY_BLOG_CREATED = 'Blog created successfully'
const ADMIN_COPY_BLOG_VALIDATION = 'Validation failed'
const ADMIN_COPY_BLOG_PERMISSION = 'No permissions to perform this action.'

// edit order

const ADMIN_COPY_EDIT_ORDER_COMMON_MSG = "This will change all the item's status related to this order. Are you sure?"

// event

const ADMIN_COPY_EVENT_VIDEO_LIMIT = 'You can upload maximum 2 videos'
const ADMIN_COPY_EVENT_VIDEO_FILE_SIZE = 'File size should be less than or equal to 20MB'
const ADMIN_COPY_EVENT_CUSTOM_CATEGORY_IMG = 'Image for all custom categories are required'
const ADMIN_COPY_EVENT_IMG_FILE_SIZE = 'File size should be less than or equal to 5MB'
const ADMIN_COPY_EVENT_IMG_SIZE = 'Image is too small it should be at least 1125x1350PX.'
const ADMIN_COPY_CUSTOM_CATEGORY_IMAGE_DIMENSIONS = 'Image is too small it should be at least 375*450px.'
const ADMIN_COPY_EVENT_LANDSCAPE_IMG_SIZE = 'Image is too small it should be at least 1908x954px.'
const ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMG_SIZE = 'Image is too small it should be at least 1908x532px.'
const ADMIN_COPY_EVENT_BROWSE_IMG_SIZE = 'Image is too small should need atleast 300x200PX'
const ADMIN_COPY_EVENT_NO_IMG = 'You need to upload images for the Event!'
const ADMIN_COPY_EVENT_GOAL = "If you don't want to track goal raised enter NA"
const ADMIN_COPY_EVENT_CUSTOM_URL_LENGTH = 'Custom URL must be minimum 3 and maximum 30 characters.'
const ADMIN_COPY_EVENT_CUSTOM_URL_REGEX_ERR = 'Please use only alphanumeric characters or hyphens (-) in the custom URL. Avoid using white spaces, other symbols or special characters.'
const ADMIN_COPY_EVENT_CUSTOM_URL_EXIST = 'Custom URL already taken. Please choose another.'
const ADMIN_COPY_EVENT_UPDATE = 'Event data updated successfully'
const ADMIN_COPY_EVENT_DATE_EDIT_MSG = "Are you sure you want to change event's start and end date? This will reset all items start and end date associated with this event."
const ADMIN_COPY_EVENT_TERMS_ENTRY_MSG = "You need to fil initial event details first!"
const ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG = "By removing this category, any items not linked to another category will be sent to the category, “Other”"
const ADMIN_COPY_ALL_CUSTOM_CATEGORY_CLEAR_STATE_MSG = "Are you sure? You want to remove all these custom categories?"
const ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING = "By removing this category, any items not linked to another category will be sent to the category, “Other”"
const ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING_STATE_CLEAR = " Are you sure? You want to remove this category and its associated custom categories?"
const ADMIN_COPY_EVENT_HASAUCTION_AND_HASBUYNOW_VALIDATION = "You must select at least one item type to create items for your event."
const ADMIN_COPY_EVENT_GOAL_INPUT = "Enter how much you’re hoping to raise from this event. Our event tracker will keep a tally of every dollar raised. If you don’t want to track a specific goal, enter NA. When entering number do not use commas."
const ADMIN_COPY_EVENT_TERMS_INFO_LABEL = "Add your Event Terms and Conditions"
const ADMIN_COPY_EVENT_TERMS_INFO = "Please enter event specific terms and conditions"
const ADMIN_COPY_EVENT_TEXT_COLOR_LABEL = "Event title font color:"
const ADMIN_COPY_EVENT_BOX_COLOR_RADIO = 'Would you like to encase your main event details within a box?'
const ADMIN_COPY_EVENT_BOX_COLOR_LABEL = 'Store Title Background Color'
const ADMIN_COPY_TITLE_VALIDATION = "title must be minimum 2 and maximum 60 characters."
const ADMIN_COPY_EVENT_BUY_NOW_ITEMS_RADIO = 'Would you like to sell anything outside of auction items? (e.g. direct donations, tickets, goods and services, etc)'
const ADMIN_COPY_EVENT_AUCTION_ITEMS_RADIO = 'Would you like to display auction items in your event?'
const ADMIN_COPY_EVENT_DATES_VALIDATION = 'Please provide a start date and an end date for your event'
const ADMIN_COPY_EVENT_SHOW_AFTER_COMPLETED_LABEL = 'Would you like the event to remain displayed on the website even after it has concluded?'
const ADMIN_COPY_EVENT_CLONE_CONFIRM = 'This will create one more Event with same data of the selected Event.'
const ADMIN_COPY_EVENT_CLONE_SUCCESS = 'Successfully cloned event.'
// edit event terms

const ADMIN_COPY_EDIT_TERMS_UPDATE_SUCCESS = "Terms Updated Successfully"
const ADMIN_COPY_EDIT_TERMS_UPDATE_ERROR = 'Please add terms and conditions to your store/event'

// auction/store item

const ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX = 'you can upload max 5 files'
const ADMIN_COPY_ITEM_IMG_FILE_SIZE = 'File size should be less than or equal to 5MB'
const ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE = 'Image is too small it should be at least 300x300.'
const ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX = 'Maximum number of portrait images are 4'
const ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE = 'Image is too small it should be at least 600x600px.'
const ADMIN_COPY_ITEM_IMG_LANDSCAPE = 'You need to provide at least 1 portrait image'
const ADMIN_COPY_ITEM_IMG_POTRAIT = 'You need to provide 1 portrait image'
const ADMIN_COPY_ITEM_PHYSICAL_PROPERTY = 'Physical Item missing property (Weight)'
const ADMIN_COPY_ITEM_DONOR = 'Please Select Donor'
const ADMIN_COPY_ITEM_CATEGORY = 'Please select the category that your product belongs'
const ADMIN_COPY_ITEM_RESERVE_PRICE_ERROR = 'Reserve price must be greater than the list price!'
const ADMIN_COPY_ITEM_BUY_NOW_PRICE = "Buy now price must be greater than or equal to list price and reserve price!"
const ADMIN_COPY_VIRTUAL_ITEM = 'Please Provide Email instructions or upload instructions'
const ADMIN_COPY_ITEM_SELECT_CAUSE = 'Please select cause'
const ADMIN_COPY_ITEM_CREATE_FAILURE ='Failed to create auction item!'
const ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD = 'Please fill all fields'
const ADMIN_COPY_STORE_ITEM_QUANTITY_ERROR = 'Total Quantity Should Match Sum of Quantities of Variants.'
const ADIMIN_COPY_CAN_CHOOSE_CAUSE = "Do you want to give the buyer multiple choices of charities where the proceeds of their purchase will go?"
const ADMIN_COPY_ITEM_THUMPNAIL_IMG = "Add a thumbnail image."
const ADMIN_COPY_ITEM_DETAILS_IMG = "Add images for item details."
const ADMIN_COPY_ADVATIX_ITEM_QUANTITY_IN_STOCK_ALERT = 'Setting a quantity before the item is in the warehouse is not recommended as we may overcount supply. Do you understand the risk?'
const ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT = "You don't have any other shipping option."
const ADMIN_COPY_ITEM_ARCHIVED_ALERT = 'By doing this. This item will not be visible in the app for buyers.'
const ADMIN_COPY_ITEM_UNARCHIVED_ALERT = 'By doing this. The item will be visible in the app for buyers.'
const ADMIN_COPY_ITEM_ARCHIVED_SUCCESS = 'Successfully archived item.'
const ADMIN_COPY_EDI_SHIPMENT_ALERT = 'For selecting EDI shipment you need a donor who is an EDI trading partner.'

const ADMIN_COPY_CLONE_ITEM_ALERT = 'This will create one more item in this event with the same data of the selected item.'

const ADMIN_COPY_AI_MODAL_TITLE = 'AI generated data about the uploaded image.'
const ADMIN_COPY_ITEM_BUYER_ALERT = 'This will show the  details of the buyers for the item.'
const ADMIN_COPY_ITEM_BUYER_MODAL_TITLE = 'Buyers And Order Details'
const ADMIN_COPY_ITEM_CASH_ON_DELIVERY_LABEL = 'Would you prefer to pay for this item upon delivery?'
const ADMIN_COPY_ITEM_CASH_ON_DELIVERY_ALERT = "Unfortunately, we can't offer cash on delivery for virtual items."
const ADMIN_COPY_ITEM_STOCK_AVAILABILITY = 'Are you interested in displaying stock availability details on the website?'
const ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_RADIO_LABEL = "Do you plan to display both auction items and store items?"
const ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_LABEL = ["Create a label and button for your auction:", "Create a label and button for your shop:"]
const ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_BANNER_LABEL = 'Do you want to show the event details over the store page banner?'
const ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_AUCTION_TYPE = 'Is this a Flash Sale event?'
const ADMIN_COPY_EVENT_DETAILS_OVER_AUCTION_PAGE_BANNER_LABEL = 'Do you want to show the event details over the auction page banner?'
const ADMIN_COPY_EVENT_DETAILS_OVER_BANNER_LABEL = 'Do you want to show the event details over the event banner?'
const ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT = 'Vendor SKU id is required for an EDI/SPS product.'
const ADMIN_COPY_ITEM_EDI_INFO = 'This field is required if you are choosing EDI/SPS as a shipping provider.'
const ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT = 'Cannot include white space in Vendor SKU Id.'
const ADMIN_COPY_ITEM_ALL_VARIANT_VENDOR_SKU_ALERT = 'Vendor SKU Id is required for all variants if the product belongs to EDI shipment.'
const ADMIN_COPY_ITEM_VENDOR_SKU_DUPLICATE_ALERT = 'Cannot enter same Vendor SKU Id for different variants.'
const ADMIN_COPY_ITEM_VENDOR_SKU_MISSING_MULTIPLE_ITEM = 'Some of the EDI/SPS shipping items or variants are missing a vendor SKU ID. Please ensure that all items have a vendor SKU ID before activation.'
const ADMIN_COPY_DELETED_VARIANT_SUCCESS_MSG = 'Successfully Deleted Variant.'
const ADMIN_COPY_ITEM_VARIANT_DELETING_ALERT = 'Please ensure that no buyer has purchased this item before deleting a variant. Do you want to continue?'
const ADMIN_COPY_ITEM_DELETE_LAST_VARIANT_ALERT = "You can't delete all variants of an item, but you can edit the last remaining one."
const ADMIN_COPY_ITEM_VARIANT_DUPLICATE = 'Duplicate variant names are not allowed.'
const ADMIN_COPY_ITEM_OPTION_DUPLICATE = 'Duplicate option names are not allowed.'
const ADMIN_COPY_ITEM_PRIORITY_UPDATED = 'Successfully updated priority order of item.'

// manage donor

const ADMIN_COPY_MANAGE_DONOR_LOGO = "Logo is required"
const ADMIN_COPY_MANAGE_DONOR_FEATURED_IMAGE = 'Featured icon is required'
const ADMIN_COPY_MANAGE_DONOR_IMAGE_FILE_SIZE = 'File size should be less than or equal to 5MB'
const ADMIN_COPY_MANAGE_DONOR_IMAGE_SIZE = 'Image is too small it should be at least 300x300.'
const ADMIN_COPY_DONOR_SPLASH_IMAGE_REQUIRED_ALERT = "To have a donor featured, please provide a featured logo."
const ADMIN_COPY_DONOR_FEATURED_ICON_REQUIRED_ALERT = "To have a donor featured, please provide a featured icon."
const ADMIN_COPY_DONOR_SPLASH_IMAGE_INFO_TEXT = `The image will be displayed within the "About Donor" section of the Boundless app in mobile.`
const ADMIN_COPY_DONOR_FEATURED_ICON_INFO_TEXT = "If the donor qualifies as a featured contributor, their recognition will be prominently displayed on the homepage of the Boundless app within the Boundless Marketplace section with this image."
const ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME = "Organisation name must be at least 2 chars long"
const ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT = "About must be at least 2 chars long"
const ADMIN_COPY_DONOR_EDI_PARTNER_ALERT = "Only numbers are allowed for EDI partner id."
const ADMIN_COPY_DONOR_EDI_PARTNER_REQUIRED_ALERT = "Please enter EDI trading partner id."

// preview event edit item

const ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM = 'You are not allowed to edit completed auction item'
const ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM_PERMISSION = `Sorry you don't have permission to perform this action`

// signup 

const ADMIN_COPY_SIGNUP_SUCCESS_MSG = 'Registration Successful! Check your email for a verification link to get started.'
const ADMIN_COPY_SIGNUP_AGREE_TERMS = 'Please agree Terms of use and privacy policy '

// user management

const ADMIN_COPY_USER_MANAGEMENT_MISSING_CREDENTIAL = 'Missing credentials'
const ADMIN_COPY_USER_MANAGEMENT_CONFIRM_EMAIL = 'Confirm Email must be same as the email address'
const ADMIN_COPY_USER_MANAGEMENT_SELECT_PERMISSION = 'Select the permissions you want to assign'
const ADMIN_COPY_USER_MANAGEMENT_PERMISSION_NOTIFICATION = 'Permissions assigned and notification mail sent'
const ADMIN_COPY_USER_MANAGEMENT_NO_PERMISSION = 'You dont have access to give permissions'

// market or stores
const ADMIN_COPY_CHARITY_PORTRAIT_IMAGE_SIDELABEL = `Max 1, Recommended size 1125x1350px`
const ADMIN_COPY_CHARITY_LANDSCAPE_IMAGE_SIDELABEL = `Max 1, Recommended size 1908x954px`
const ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMAGE_SIDELABEL = `Max 1, Recommended size 1908x532px`
const ADMIN_COPY_CHARITY_BROWSE_AUCTION_IMAGE_SIDELABEL = `Max 1, Recommended size 300x200px`


// white border popup alert msg
const ADMIN_COPY_WHITE_BORDER_ALERT_MSG = `Your image width and height will result in white borders on the final image.  We recommend uploading closer to the proper aspect ratio () for better display`

// orders and reports
const ADMIN_COPY_END_AUCTION_ALERT = 'Are you sure to close this running auction?';
const ADMIN_COPY_DONATED_ITEM_ALERT = 'This order contains items donated from an external source and cannot be edited.'

///asn
const ADMIN_COPY_ASN_TABLE_ROW_VALIDATION = 'Please fill all the fields'
const ADMIN_COPY_ASN_CREATED_ALERT = 'ASN record created successfully'
const ADMIN_COPY_ASN_CREATION_ERROR = "Something went wrong!"
const ADMIN_COPY_ASN_NO_PRODUCTS = "No products to show."
const ADMIN_COPY_FILTER_BY_EVENT = "Filter by Event"
const ADMIN_COPY_EVENT_DELETE_CONFIRM = "Are you sure you want to delete this event?"
const ADMIN_COPY_CAUSE_DELETE_CONFITM = "Are you sure you want to archive this cause?"
const ADMIN_COPY_CAUSE_RESTORE_CONFIRM = "Are you sure you want to restore this cause?"
const ADMIN_COPY_ASN_FILTER_SHOW_SENT = "Show Sent"
const ADMIN_COPY_ASN_UPDATED_ALERT = "Quantity and ASN status udpated successfully!"
const ADIMIN_COPY_UPDATE_ASN_AND_QUANTITY_ALERT = `This will update the quantiy of the item, if the item is a store item and asn status to 'processed'.`
// const ADMIN_COPY_UPDATE_ALL_ALERT = `This will update all store items with their saved ASN quantity and status as 'processed'. To change a product's quantity, use the table form and update it from there.`
const ADMIN_COPY_UPDATE_ALL_ALERT = `This will update the quantiy of the items in this page (if the item is a store item) and asn status to 'processed'.`
// const ADMIN_COPY_ADD_ALL_TOOLTIP = `This will select all known items for sending to Advatix.`
const ADMIN_COPY_ADD_ALL_TOOLTIP = `This will select all items in this page.`
const ADMIN_COPY_ASN_FILE_UPLOADED_SUCESS = 'File uploaded and asn status and product quantity updated successfully'
const ADMIN_COPY_ASN_SHOW_ALL_KNOWN_ITEMS = 'Show All Known Items'

//available items
const ADMIN_COPY_ADD_ALL_ALERT = "By doing this all the selected items will added to your event."

// char limit

const ADMIN_COPY_MAX_CHAR_CUSTOM_HEADING_CATEGORY = 'Maximum character limit reached. Please reduce your text.'
export const COLORS_OBJECT = {
    // red, black, shadywhite, transparent, white
    backgroundColor:['#FC3300', "#30313A", "#F0E8E3", "transparent", "#ffffff" ],
    color:['#FC3300', "#30313A", "#F0E8E3","black"]
  }

// saleintegration
const ADMIN_COPY_SALE_INTEGRATION_METHOD_ADMIN_ONLY_LABEL1 = "Donor will use admin for order management and reports"
const ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PUSH_LABEL2 = "Donor has API which BG will send order updates to"
const ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PULL_LABEL3 = "Donor will use BG API to get order updates"
const ADMIN_COPY_SALE_INTEGRATION_METHOD_EMAIL_LABEL4 = "Donor will receive daily email with order updates"

//store 
const ADIMIN_COPY_FEATURED_STORE_IMAGE_LABEL = 'Featured Shop Icon'

// category display_order
const ADMIN_COPY_CATEGORY_ORDER_UPDATED = 'Successfully updated priority order of categories.'

//qr code
const ADMIN_COPY_QRCODE_SELECT_ALL_LABEL = 'Select All Items'

/// common to all
const ADMIN_COPY_CLEAR_UNSAVED_CHANGES = 'Clear Unsaved changes';
const ADMIN_COPY_CAUSE_ID_REQUIRED = 'Please select a cause'
const ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN = "This item is not known to Advatix"
const ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER = "This item is not known to Advatix, if you approve and somebody buys it it will not be fulfilled. Are you sure you want to approve?"
const ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_NORMAL_USER = "Are you sure you want to make this item active?"
const ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER_ALL_ITEMS = "Some of the item is not known to Advatix, if you approve and somebody buys it it will not be fulfilled. Are you sure you want to approve?"
const ADMIN_COPY_ITEM_APPROVE_ALERT_NORMAL_USER = "Are you sure you want to make all items active?"
const ADMIN_COPY_SOMETHING_WENT_WRONG = "Something went wrong!"
const ADMIN_COPY_UPDATED_SUCCESS_MSG = 'Data updated successfully!'
const ADMIN_COPY_IMG_SIZE = 'Image is too small it should be at least ()'
const ADMIN_COPY_FEATURED_IMG_SIZE = 'Max 1, Recommended size 942x280px'
const ADMIN_COPY_FEATURED_ALERT = 'Successfully Updated Event to Featured'
const ADMIN_COPY_UNFEATURED_ALERT = 'Successfully Unfeature the Event'
const ADMIN_COPY_PLEASE_CHOOSE_FILE = 'Please choose a file'
const ADMIN_COPY_BAD_NETWORK = 'Oops! Seems you have bad connection'
const ADMIN_COPY_UNAUTHORIZED_USER = 'You are not Authorized to perform this action.'
const ADMIN_COPY_EDI_SHIPMENT_LABEL = 'Shipping & Handling Belongs to EDI';

const constants = {
    ADMIN_COPY_BID_INCREMENT,
    ADMIN_COPY_BID_UPPER_BAND,
    ADMIN_COPY_BID_UPPER_LOWER_ERROR,
    ADMIN_COPY_BLOG_CONTENT_LENGTH,
    ADMIN_COPY_BLOG_CREATED,
    ADMIN_COPY_BLOG_FILED_ERROR,
    ADMIN_COPY_BLOG_IMAGE_SIZE,
    ADMIN_COPY_BLOG_IMG_FILE_SIZE,
    ADMIN_COPY_BLOG_PERMISSION,
    ADMIN_COPY_BLOG_SUB_TITLE_LENGTH,
    ADMIN_COPY_BLOG_TITLE_LENGTH,
    ADMIN_COPY_BLOG_VALIDATION,
    ADMIN_COPY_CAUSE_CATEGORY,
    ADMIN_COPY_CAUSE_COVER_IMG,
    ADMIN_COPY_CAUSE_FIRST_NAME,
    ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_FILE_SIZE,
    ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_LIMIT,
    ADMIN_COPY_CAUSE_LAND_SCAPE_IMG_SIZE,
    ADMIN_COPY_CAUSE_LAST_NAME,
    ADMIN_COPY_CAUSE_VIDEO_LIMIT,
    ADMIN_COPY_CAUSE_ZIP_CODE,
    ADMIN_COPY_CUSTOM_BID_SAVE,
    ADMIN_COPY_DEFAULT_BID_SAVE,
    ADMIN_COPY_DONOR_ABOUT,
    ADMIN_COPY_DONOR_ADDRESS,
    ADMIN_COPY_DONOR_CITY,
    ADMIN_COPY_DONOR_FIRST_NAME,
    ADMIN_COPY_DONOR_LAST_NAME,
    ADMIN_COPY_DONOR_ORG_NAME,
    ADMIN_COPY_DONOR_PROMO_FIELD_ERROR,
    ADMIN_COPY_DONOR_SELECT_EVENT,
    ADMIN_COPY_DONOR_FEATURED_IMAGE,
    ADMIN_COPY_DONOR_STATE,
    ADMIN_COPY_DONOR_TAXID,
    ADMIN_COPY_DONOR_TAX_ID_NUMERIC,
    ADMIN_COPY_DONOR_ZIP,
    ADMIN_COPY_EDIT_ORDER_COMMON_MSG,
    ADMIN_COPY_EDIT_TERMS_UPDATE_ERROR,
    ADMIN_COPY_EDIT_TERMS_UPDATE_SUCCESS,
    ADMIN_COPY_EVENT_BROWSE_IMG_SIZE,
    ADMIN_COPY_EVENT_CUSTOM_CATEGORY_IMG,
    ADMIN_COPY_EVENT_CUSTOM_URL_EXIST,
    ADMIN_COPY_EVENT_CUSTOM_URL_LENGTH,
    ADMIN_COPY_EVENT_CUSTOM_URL_REGEX_ERR,
    ADMIN_COPY_EVENT_DATE_EDIT_MSG,
    ADMIN_COPY_EVENT_GOAL,
    ADMIN_COPY_EVENT_IMG_FILE_SIZE,
    ADMIN_COPY_EVENT_IMG_SIZE,
    ADMIN_COPY_CUSTOM_CATEGORY_IMAGE_DIMENSIONS,
    ADMIN_COPY_EVENT_LANDSCAPE_IMG_SIZE,
    ADIMIN_COPY_CAUSE_STATE,
    ADMIN_COPY_CAUSE_CREATE_FAILURE,
    ADMIN_COPY_EVENT_VIDEO_LIMIT,
    ADMIN_COPY_EVENT_VIDEO_FILE_SIZE,
    ADMIN_COPY_EVENT_NO_IMG,
    ADMIN_COPY_EVENT_UPDATE,
    ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX,
    ADMIN_COPY_ITEM_IMG_FILE_SIZE,
    ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE,
    ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX,
    ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE,
    ADMIN_COPY_ITEM_IMG_LANDSCAPE,
    ADMIN_COPY_ITEM_IMG_POTRAIT,
    ADMIN_COPY_ITEM_PHYSICAL_PROPERTY,
    ADMIN_COPY_ITEM_DONOR,
    ADMIN_COPY_ITEM_CATEGORY,
    ADMIN_COPY_ITEM_RESERVE_PRICE_ERROR,
    ADMIN_COPY_ITEM_BUY_NOW_PRICE,
    ADMIN_COPY_VIRTUAL_ITEM,
    ADMIN_COPY_ITEM_SELECT_CAUSE,
    ADMIN_COPY_ITEM_CREATE_FAILURE,
    ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD,
    ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME,
    ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT,
    ADMIN_COPY_STORE_ITEM_QUANTITY_ERROR,
    ADMIN_COPY_MANAGE_DONOR_LOGO,
    ADMIN_COPY_MANAGE_DONOR_FEATURED_IMAGE,
    ADMIN_COPY_MANAGE_DONOR_IMAGE_FILE_SIZE,
    ADMIN_COPY_MANAGE_DONOR_IMAGE_SIZE,
    ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM,
    ADMIN_COPY_SIGNUP_SUCCESS_MSG,
    ADMIN_COPY_SIGNUP_AGREE_TERMS,
    ADMIN_COPY_USER_MANAGEMENT_MISSING_CREDENTIAL,
    ADMIN_COPY_USER_MANAGEMENT_CONFIRM_EMAIL,
    ADMIN_COPY_USER_MANAGEMENT_SELECT_PERMISSION,
    ADMIN_COPY_USER_MANAGEMENT_PERMISSION_NOTIFICATION,
    ADMIN_COPY_USER_MANAGEMENT_NO_PERMISSION,
    ADMIN_COPY_DONOR_INVALID_FIRST_NAME,
    ADMIN_COPY_DONOR_INVALID_LAST_NAME,
    ADMIN_COPY_DONOR_EMAIL,
    ADMIN_COPY_DONOR_EMAIL_INVALID,
    ADMIN_COPY_EVENT_TERMS_ENTRY_MSG ,
    ADMIN_COPY_PREVIEW_EVENT_EDIT_ITEM_PERMISSION,
    ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG,
    ADMIN_COPY_ALL_CUSTOM_CATEGORY_CLEAR_STATE_MSG,
    ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING,
    ADMIN_COPY_CUSTOM_CATEGORY_DELETE_MSG_BY_HEADING_STATE_CLEAR,
    ADMIN_COPY_DONOR_SPLASH_IMAGE_REQUIRED_ALERT,
    ADMIN_COPY_DONOR_FEATURED_ICON_REQUIRED_ALERT,
    ADMIN_COPY_DONOR_SPLASH_IMAGE_INFO_TEXT,
    ADMIN_COPY_DONOR_FEATURED_ICON_INFO_TEXT,
    ADIMIN_COPY_CAN_CHOOSE_CAUSE,
    ADMIN_COPY_CHARITY_PORTRAIT_IMAGE_SIDELABEL,
    ADMIN_COPY_CHARITY_LANDSCAPE_IMAGE_SIDELABEL,
    ADMIN_COPY_CHARITY_BROWSE_AUCTION_IMAGE_SIDELABEL,
    ADIMIN_COPY_CAUSE_COVER_PHOTO,
    ADMIN_COPY_EVENT_HASAUCTION_AND_HASBUYNOW_VALIDATION,
    ADMIN_COPY_EVENT_GOAL_INPUT,
    ADMIN_COPY_EVENT_TERMS_INFO,
    ADMIN_COPY_EVENT_TERMS_INFO_LABEL,
    ADMIN_COPY_EVENT_TEXT_COLOR_LABEL,
    ADMIN_COPY_EVENT_BOX_COLOR_RADIO,
    ADMIN_COPY_EVENT_BOX_COLOR_LABEL,
    ADMIN_COPY_CAUSE_LOGO_REQURIED,
    ADMIN_COPY_ASN_TABLE_ROW_VALIDATION,
    ADMIN_COPY_ASN_CREATED_ALERT,
    ADMIN_COPY_ASN_UPDATED_ALERT,
    ADMIN_COPY_ASN_CREATION_ERROR,
    ADMIN_COPY_ASN_NO_PRODUCTS,
    ADMIN_COPY_FILTER_BY_EVENT,
    ADMIN_COPY_ITEM_THUMPNAIL_IMG,
    ADMIN_COPY_ITEM_DETAILS_IMG,
    ADMIN_COPY_CAUSE_LOGO_SIZE,
    ADMIN_COPY_WHITE_BORDER_ALERT_MSG,
    ADMIN_COPY_EVENT_BUY_NOW_ITEMS_RADIO,
    ADMIN_COPY_EVENT_AUCTION_ITEMS_RADIO,
    ADMIN_COPY_MAX_CHAR_CUSTOM_HEADING_CATEGORY,
    ADMIN_COPY_EVENT_DELETE_CONFIRM,
    ADMIN_COPY_CAUSE_DELETE_CONFITM,
    ADMIN_COPY_CAUSE_RESTORE_CONFIRM,
    ADMIN_COPY_TITLE_VALIDATION,
    ADMIN_COPY_ASN_FILTER_SHOW_SENT,
    ADMIN_COPY_CLEAR_UNSAVED_CHANGES,
    ADMIN_COPY_CAUSE_ID_REQUIRED,
    ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN,
    ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER,
    ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_NORMAL_USER,
    ADMIN_COPY_ITEM_ADVATIX_STATUS_KNOWN_GOD_USER_ALL_ITEMS,
    ADMIN_COPY_ITEM_APPROVE_ALERT_NORMAL_USER,
    ADMIN_COPY_DONOR_SHARE_PERCENTAGE_LABEL,
    ADMIN_COPY_BG_SHARE_PERCENTAGE_LABEL,
    ADMIN_COPY_CAUSE_SHARE_PERCENTAGE_LABEL,
    ADMIN_COPY_DONOR_DROPSHIP_SALE_INTEGRATION_LABEL,
    ADMIN_COPY_TOTAL_SHARE_VALIDATION,
    ADMIN_COPY_INTEGRATION_TYPE_VALIDATION,
    ADMIN_COPY_SOMETHING_WENT_WRONG,
    ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMG_SIZE,
    ADMIN_COPY_MARKETPLACE_LANDSCAPE_IMAGE_SIDELABEL,
    ADMIN_COPY_SALE_INTEGRATION_METHOD_ADMIN_ONLY_LABEL1,
    ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PUSH_LABEL2,
    ADMIN_COPY_SALE_INTEGRATION_METHOD_API_PULL_LABEL3,
    ADMIN_COPY_SALE_INTEGRATION_METHOD_EMAIL_LABEL4,
    ADMIN_COPY_ADD_ALL_ALERT,
    ADIMIN_COPY_UPDATE_ASN_AND_QUANTITY_ALERT,
    ADMIN_COPY_EVENT_DATES_VALIDATION,
    ADMIN_COPY_UPDATE_ALL_ALERT,
    ADMIN_COPY_ADD_ALL_TOOLTIP,
    ADMIN_COPY_UPDATED_SUCCESS_MSG,
    ADMIN_COPY_DELETED_VARIANT_SUCCESS_MSG,
    ADMIN_COPY_ITEM_DELETE_LAST_VARIANT_ALERT,
    ADIMIN_COPY_FEATURED_STORE_IMAGE_LABEL,
    ADMIN_COPY_IMG_SIZE,
    ADMIN_COPY_FEATURED_IMG_SIZE,
    ADMIN_COPY_FEATURED_ALERT,
    ADMIN_COPY_UNFEATURED_ALERT,
    ADMIN_COPY_PLEASE_CHOOSE_FILE,
    ADMIN_COPY_ASN_FILE_UPLOADED_SUCESS,
    ADMIN_COPY_ADVATIX_ITEM_QUANTITY_IN_STOCK_ALERT,
    ADMIN_COPY_BAD_NETWORK,
    ADMIN_COPY_END_AUCTION_ALERT,
    ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT,
    ADMIN_COPY_CATEGORY_ORDER_UPDATED,
    ADMIN_COPY_DONATED_ITEM_ALERT,
    ADMIN_COPY_ITEM_UNARCHIVED_ALERT,
    ADMIN_COPY_ITEM_ARCHIVED_ALERT,
    ADMIN_COPY_ITEM_ARCHIVED_SUCCESS,
    ADMIN_COPY_EDI_SHIPMENT_LABEL,
    ADMIN_COPY_EVENT_SHOW_AFTER_COMPLETED_LABEL,
    ADMIN_COPY_AI_MODAL_TITLE,
    ADMIN_COPY_ITEM_BUYER_ALERT,
    ADMIN_COPY_ITEM_BUYER_MODAL_TITLE,
    ADMIN_COPY_UNAUTHORIZED_USER,
    ADMIN_COPY_CLONE_ITEM_ALERT,
    ADMIN_COPY_ITEM_CASH_ON_DELIVERY_LABEL,
    ADMIN_COPY_ITEM_CASH_ON_DELIVERY_ALERT,
    ADMIN_COPY_ITEM_STOCK_AVAILABILITY,
    ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_RADIO_LABEL,
    ADMIN_COPY_EVENT_AUCTION_AND_BUYNOW_ITEMS_LABEL,
    ADMIN_COPY_EVENT_DETAILS_OVER_BANNER_LABEL,
    ADMIN_COPY_DONOR_EDI_PARTNER_ALERT,
    ADMIN_COPY_EDI_SHIPMENT_ALERT,
    ADMIN_COPY_DONOR_EDI_PARTNER_REQUIRED_ALERT,
    ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT,
    ADMIN_COPY_ITEM_EDI_INFO,
    ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT,
    ADMIN_COPY_ITEM_ALL_VARIANT_VENDOR_SKU_ALERT,
    ADMIN_COPY_ITEM_VENDOR_SKU_DUPLICATE_ALERT,
    ADMIN_COPY_ITEM_VENDOR_SKU_MISSING_MULTIPLE_ITEM,
    ADMIN_COPY_CAUSE_FEATURED_LABEL,
    ADMIN_COPY_ASN_SHOW_ALL_KNOWN_ITEMS,
    ADMIN_COPY_QRCODE_SELECT_ALL_LABEL,
    ADMIN_COPY_ITEM_VARIANT_DELETING_ALERT,
    ADMIN_COPY_ITEM_VARIANT_DUPLICATE,
    ADMIN_COPY_ITEM_OPTION_DUPLICATE,
    ADMIN_COPY_ITEM_PRIORITY_UPDATED,
    ADMIN_COPY_EVENT_CLONE_CONFIRM,
    ADMIN_COPY_EVENT_CLONE_SUCCESS,
    ADMIN_COPY_EVENT_DETAILS_OVER_AUCTION_PAGE_BANNER_LABEL,
    ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_BANNER_LABEL,
    ADMIN_COPY_EVENT_DETAILS_OVER_STORE_PAGE_AUCTION_TYPE
}

export default constants