import React, { useEffect } from 'react'
import { useState } from 'react'
import CreateForm from './CreateForm'
import moment from "moment"
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const CreateStore = () => {
    const today = new Date()
    const startDate = moment(today).format("YYYY-MM-DDTHH:mm");
    const year = today.getFullYear();
    const month = today.getMonth();
    const day = today.getDate();
    const date = new Date(year + 100, month, day);
    const endDate = moment(date).format("YYYY-MM-DDTHH:mm");
    const {auction_id} = useParams();
    let stringLowerName = 'store'
    let stringCapitalName = 'Store'
    const localStorageData = JSON.parse(localStorage.getItem(`${stringLowerName}Data`));
    const persistedData = useSelector(state => state?.persistedData)
    let initialData = {
      title: '',
      cause_id: '',
      cause_category_id: '',
      start_date: startDate,
      end_date: endDate,
      currency: 'USD',
      shipping_info: '',
      terms_info: '',
      about: '',
      custom_categories: [],
      video: null,
      video1: null,
      image1: null,
      image2: null,
      custom_url: '',
      auction_image_key:null,
      buy_now_image_key:null,
      status:'',
      need_box: '0',
      box_color: null,
      text_color: null,
      featured_image:null,
      show_details_over_banner:true,
      ui: false,
    }

    const initialStateCopy = {...initialData}

    if(localStorageData) {
      if(auction_id) {
        localStorage.removeItem(`${stringLowerName}Data`)
        localStorage.removeItem(`extra${stringCapitalName}State`)
      }
      initialData = localStorageData
      if(persistedData?.storePortraitImage) {
        initialData = {...initialData, image1:persistedData?.storePortraitImage}
      }
      if(persistedData?.storeLandscapeImage) {
        initialData = {...initialData, image2:persistedData?.storeLandscapeImage}
      }
    }

    const [storeData, setStoreData] = useState(initialData)

    useEffect(() => {

      if (storeData.ui) {
        setStoreData(prevState => ({
          ...prevState,
          start_date: "",
          end_date: ""
        }));
      } else {
        setStoreData(prevState => ({
          ...prevState,
          start_date: startDate,
          end_date: endDate
        }));
      }

    }, []);
 
  return (
   <CreateForm stateData={storeData} setStateData={setStoreData} stringLowerName={stringLowerName} stringCapitalName={stringCapitalName} checkCause={true} initialStateCopy={initialStateCopy} />
  )
}

export default CreateStore