import Container from "../../components/HOC/Container/Container"
import {
    setDefaultStartDate,
    setDefaultEndDate,
  } from '../../../utils/util-functions';
import { useEffect, useState } from "react";
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import FormTextarea from "../../components/FormTextarea/FormTextarea"
import {useDispatch, useSelector} from "react-redux"

import "./Terms.css"
import { useNavigate, useParams } from "react-router-dom";
import auctionServices from "../../../services/auction-services";
import LoadingModal from "../../components/LoadingModal/LoadingModal";
import { updateEvent } from "../../../redux/dependenciesReducer";
import { updateProperties } from "../../../redux/savedEventReducer";
import usePrompt from "../../hooks/usePrompt"
import Alert from "../../components/Alert/Alert";
import constants from "../../../constants/en";
import { useRef } from "react";
import QuillEditor from "../../components/QuillEditor/QuillEditor";
import usePopState from "../../hooks/usePopState";
import { setItemToLocalStorage } from "../../../utils/autoSave";

const Terms=  () => {
    const savedEvent = useSelector(state => state.savedEvent)
    const dispatch = useDispatch()
    const [errorMsg, setErrorMsg] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [showPrompt, setShowPrompt] = useState(true)
    const [alertMsg, setAlertMsg] = useState(null)
    useEffect(() => {
      if(!savedEvent.event) {
        alert(constants. ADMIN_COPY_EVENT_TERMS_ENTRY_MSG)
        navigate("/admin/event", {replace: true})
        return
      }
    }, [savedEvent.event])
    
    usePrompt("Do you want to leave?", showPrompt, 'eventData', savedEvent?.event)
    // usePopState('eventData', savedEvent?.event, showPrompt)

    useEffect(() => {
      if(showPrompt) {
        // localStorage.setItem('eventData', JSON.stringify(savedEvent?.event))
        setItemToLocalStorage('eventData', savedEvent?.event)
      }
    },[savedEvent.event?.shipping_info, savedEvent.event?.terms_info])
    
    const modules = {
      toolbar: false
    }

    const handleSubmit= async (e)=>{
        e.preventDefault()
        setIsLoading(true)
        const eventData = savedEvent.event
        let auctionDetailsFormData = new FormData();
        eventData.title &&
          auctionDetailsFormData.append('title', eventData.title);
        eventData.cause_id &&
          auctionDetailsFormData.append('cause_id', eventData.cause_id);
        eventData.cause_category_id &&
          auctionDetailsFormData.append(
            'cause_category_id',
            eventData.cause_category_id
          );
        eventData.start_date &&
          auctionDetailsFormData.append(
            'start_date',
            setDefaultStartDate(eventData.start_date)
          );
        eventData.end_date &&
          auctionDetailsFormData.append(
            'end_date',
            setDefaultEndDate(eventData.end_date)
          );
        eventData.preview_periods_days &&
          auctionDetailsFormData.append(
            'preview_periods_days',
            eventData.preview_periods_days
          );
        eventData.currency &&
          auctionDetailsFormData.append('currency', eventData.currency);
        auctionDetailsFormData.append('shipping_info', eventData.shipping_info);
        auctionDetailsFormData.append('terms_info', eventData.terms_info);
        eventData.about &&
          auctionDetailsFormData.append('about', eventData.about);
        eventData.image1 &&
          auctionDetailsFormData.append('image1', eventData.image1);
        eventData.image2 &&
          auctionDetailsFormData.append('image2', eventData.image2);
          eventData.auction_image_key && 
          auctionDetailsFormData.append('auction_image_key', eventData.auction_image_key);

          eventData.buy_now_image_key && 
          auctionDetailsFormData.append('buy_now_image_key', eventData.buy_now_image_key);
        eventData?.featured_image &&
          auctionDetailsFormData?.append('featured_image', eventData?.featured_image)
        eventData.video &&
          auctionDetailsFormData.append('video', eventData.video);
        eventData.goal &&  (!isNaN(eventData.goal)) &&
          auctionDetailsFormData.append('goal', eventData.goal);
        eventData.custom_url && 
          auctionDetailsFormData.append('custom_url', eventData.custom_url.replace(/ /g, "-"))  
        eventData?.event_logo && 
          auctionDetailsFormData.append("event_logo",eventData?.event_logo)
        auctionDetailsFormData.append('has_buy_now', eventData.has_buy_now);
        auctionDetailsFormData.append('has_auction', eventData.has_auction);
        auctionDetailsFormData.append('need_box', eventData?.need_box);
        eventData.box_color && 
          auctionDetailsFormData.append("box_color", eventData.box_color);
        eventData.text_color && 
          auctionDetailsFormData.append("text_color", eventData.text_color);
          auctionDetailsFormData.append("remain_visible", eventData.remain_visible ? 1 : 0);
          auctionDetailsFormData.append("show_details_over_banner", eventData.show_details_over_banner ? 1 : 0)
          if(eventData.has_auction){
            auctionDetailsFormData.append('auction_label',eventData?.auction_label);
            auctionDetailsFormData.append('auction_btn_name',eventData?.auction_btn_name)
          }
          if(eventData.has_auction){
            auctionDetailsFormData.append('buy_now_label',eventData?.buy_now_label);
            auctionDetailsFormData.append('buy_now_btn_name',eventData?.buy_now_btn_name)
          }
          auctionDetailsFormData.append("ui", eventData.ui ? 'flash_sale' : 'default')
          if(eventData.ui) {
            auctionDetailsFormData.append("discount_percentage", eventData.discount_percentage);
          }
        try {
          setShowPrompt(false)
          const response = await auctionServices.createAuction(auctionDetailsFormData)
          if (response.data) {
            dispatch(updateEvent({
              type: "edit",
              auction_title: eventData.title,
              auction_id: response.data.id,
              auction_title: eventData.title,
              start_date: eventData.start_date && setDefaultStartDate(eventData.start_date),
              end_date: eventData.end_date && setDefaultEndDate(eventData.end_date),
              active:'event',
              has_auction: eventData.has_auction,
              has_buy_now: eventData.has_buy_now
            }))
            localStorage.setItem("data", JSON.stringify({
              auction_id: response.data.id,
              auction_title: eventData.title,
              start_date: eventData.start_date && setDefaultStartDate(eventData.start_date),
              end_date: eventData.end_date && setDefaultEndDate(eventData.end_date),
              has_auction: eventData.has_auction,
              has_buy_now: eventData.has_buy_now
            }))
            
            // if(eventData.custom_categories  && Object.keys(eventData.custom_categories).length && eventData.custom_category_images) {
            if(eventData.custom_categories  && Object.keys(eventData.custom_categories).length && eventData.custom_category_images) {
              const formData = new FormData()
              formData.append("customCategories",JSON.stringify(eventData.custom_categories))
  
              for(let tempId in eventData.custom_category_images){
                formData.append("customCategoryImages",eventData.custom_category_images[tempId],`${tempId}`)
              }
              await auctionServices.addCustomCategories(response.data.id,formData)
            }
            localStorage.removeItem('eventData')
            localStorage.removeItem('extraEventState')
            // setShowPrompt(true)
            setErrorMsg(null);
            setIsLoading(false);
            navigate("/admin/items/add")
          }
        } catch (error) {
          setIsLoading(false);
          // setShowPrompt(true)
          if(error?.response?.data?.message === 'This Custom URL already exists. Please try a different one'){
            setAlertMsg(constants.ADMIN_COPY_EVENT_CUSTOM_URL_EXIST)
          }         
          else if((error?.response?.status === 400) && error?.response?.data?.message === 'Validation failed'){
            setAlertMsg(`${error?.response?.data?.data[0].param} - ${error?.response?.data?.data[0].msg}`)
          }
           else {
            setAlertMsg("Validation Failed")
          }
        }
    }

    const handleTermsInfo = (e) => {
      setShowPrompt(true)
      dispatch(updateProperties({key: "terms_info", value: e}))
    }

    const handleShippingInfo = (e) => {
      setShowPrompt(true)
      dispatch(updateProperties({key: "shipping_info", value: e.target.value}))
    }

    return(
        <Container title="Step 3: Add Event Terms And Conditions" style={{position: "relative"}}>
            <div className="event-title"> <EventNoteOutlinedIcon sx={{ fontSize: 18, marginRight: "3px", color: "skyblue" }} />{savedEvent?.event?.title}</div>
            <hr style={{margin: 0, marginBottom: "10px"}} />
                <div className='row' style={{ justifyContent: 'center' }}>
                    <form onSubmit={handleSubmit} className='col-md-8 col-lg-9'>
                        {/* <FormTextarea rows={4} required label="Add your Event Terms and Conditions" value={savedEvent.event?.terms_info} onChange={handleTermsInfo} placeholder={'Please enter event specific terms and conditions'} maxsize={2000}/> */}
                        <QuillEditor
                          onChange={handleTermsInfo}
                          value={savedEvent.event?.terms_info}
                          modules={modules}
                          placeholder={constants.ADMIN_COPY_EVENT_TERMS_INFO}
                          label={constants.ADMIN_COPY_EVENT_TERMS_INFO_LABEL}
                        />
                        <FormTextarea rows={5} label="Add Your Event Shipping Information" value={savedEvent.event?.shipping_info} onChange={handleShippingInfo} placeholder={"Add any other details here like shipping,\nwhere to pick up items at end of event,\nreturn policies, etc."} maxsize={2000} />
                        <div>
                            <button style={{width:'100%'}} className="aitem-modal-btn" type="submit">Save Event Terms and Continue</button>
                        </div>
                    </form>
                </div>
          <div style={{fontSize: "0.9rem", marginTop: "20px", fontWeight: "600"}}><span style={{color: "red"}}>*</span> - required fields</div>
          <Alert isOpen={!!alertMsg} message={alertMsg} onClose={() => setAlertMsg(null)} />
          <LoadingModal isOpen={isLoading} />
        </Container>
    )
}

export default Terms